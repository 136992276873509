.subservicesdesc{
    width: 100%;
    height: auto !important;
    padding-bottom: 20px;
    // position: relative;

    .titleContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        .subtitle {
        font-size: 2.3rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 30px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }
    }
    
    
    
    .subservicesContainer{
        width: 100%;
        height: 100%;
        // position: absolute;
        display: flex;
        flex-direction: row;
        // flex: 1;
        margin-left: 150px;
        margin-right: 150px !important;
        gap: 20px; 
        // background-color: beige;
        // border: 1px solid black;
        // padding-top: 30px;
        
        .subservicesLeft{

            display: flex;
            // flex: 0.4;
            
            .subcontainer1{
                padding-top: 10px;
                width: 80%;
                height: 90%;
                display: flex;
                flex-direction: column;
                margin-left: -150px;
                .lines{
                    padding-left: 135px;
                    position: relative;
                    top: 65px;
                    width: 120%;
                    height: 3px;
                }
                // border-radius: 1px solid black;
                .box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;    
                    margin-top: 60px;
                    width: 360px;
                    height: 205px;
                    
                    // margin-left: 50px;
                    // border: 1px solid black;
                    
                    .boxleft{
                        display: flex;
                        width: 130px;
                        height: 100%;
                        padding-top: 130px;
                        padding-left: 120px;
    
                        .icons{
                            width: 65px;
                            height: 60px;
                        }
                    }
                    
                    .boxright1{
                        width: 80px;
                        // background-color: #0623D9;
                        display: flex;
                        height: 100%;
                        padding-top: 100px;
    
                        p{
                            flex-direction: column;
                            font-weight: bold;
                            width: 100%;
                            text-transform: uppercase;
                            // padding-left: 15px;
                            padding-right: 25px;
                            font-size: 1rem;
                            // text-transform: uppercase;
                            color: #D22599
        
                        }
                    }
    
    
                }
            }
    
            }
    
            .subservicesRight{
                display: flex;
                // flex: 0.6;
                .container2{
                    display: flex;
                    flex-direction: column;
                    padding-top: 55px;
                        // padding-left: 20px;
                        padding-right: 130px !important;
                    width: 100%;
                    height: 95%;
                    border-radius: 1px solid black;
                    
                    .box{
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    // justify-content: center;    
                    margin-top: 10px;
                    width: 1200px;
                    height: 255px;
                    margin-left: 50px;
                    text-align: start;
                    padding: 2px;
                    // border: 1px solid;

                        .line{
                        // margin-left: 730px;
                        width: 95%;
                        height: 3px;
                    }
        
                    p{
                        width: calc(98% - 20px);
                        line-height: 20px;
                        // margin-left: 730px;
                        font-size: 1.2rem;
        
                    }

                    .roleBtn {
                        margin-top: 20px;
                        // margin-left: 3%;
                        // margin-top: -150px;
                        width: 123px;
                        height: 34px;
                        background-color: #d22599;
                        color: #ffff;
                        border: none;
                        border-radius: 70px;
                        border: none;
                        cursor: pointer;
                      }

                    }
                }
    
            }
        // }
        
    }

    
}

@media (min-width : 905px) and (max-width: 1500px) {
  
    .subservicesContainer{
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px !important;
        gap: 35px !important;
        // padding-left: auto;
        // padding-right: auto;

        .subservicesLeft{
            display: none !important;
            .subcontainer1{
                display: none !important;
            }
            // .container{
            //     display: none;
            //     .box{
            //         width: 89px !important;
            //         height: 79px !important;
            //         margin-left: -10px;

            //         .boxleft{
            //             width: 30px !important;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             .icons{
            //                 width: 20px;
            //                 height: 20px;
            //             }
            //         }

            //         .boxright{
            //             width: 130px;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             p{
            //                 font-size: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

           .subservicesRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;

            .container2{
                margin-top: 20px !important;
                display: flex !important;
                align-items: center !important;
                height: auto !important;
                width: auto !important;
                padding-bottom:  20px !important;
                justify-content: center;
                padding-right: 50px !important;
                gap: 10px !important;
               

                .box{
                    display: flex;
                    flex-direction: column;
                    // padding-left:20px !important;
                    padding-right:110px !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    width: 780px !important;
                    margin-left: 0px !important;
                    gap: 10px !important;
                    height: auto !important;
                    padding-bottom: 4px !important;

                    p{
                        padding-top: 40px !important;
                        gap: 6px;
                        display: flex;
                        flex-direction: column;
                        width: calc(98% - 30px);
                    }


                    .roleBtn{
                        margin-top: 3px;
                    }
                    
                }



                
            }
        }


        

       
    }

 


    
}
@media (min-width : 620px) and (max-width: 900px) {
  
    .subservicesContainer{
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px !important;
        gap: 35px !important;
        // padding-left: auto;
        // padding-right: auto;

        .subservicesLeft{
            display: none !important;
            .subcontainer1{
                display: none !important;
            }
            // .container{
            //     display: none;
            //     .box{
            //         width: 89px !important;
            //         height: 79px !important;
            //         margin-left: -10px;

            //         .boxleft{
            //             width: 30px !important;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             .icons{
            //                 width: 20px;
            //                 height: 20px;
            //             }
            //         }

            //         .boxright{
            //             width: 130px;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             p{
            //                 font-size: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

           .subservicesRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;

            .container2{
                margin-top: 20px !important;
                display: flex !important;
                align-items: center !important;
                height: auto !important;
                width: auto !important;
                padding-bottom:  20px !important;
                justify-content: center;
                padding-right: 50px !important;
                gap: 10px !important;
               

                .box{
                    display: flex;
                    flex-direction: column;
                    // padding-left:20px !important;
                    padding-right:110px !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    width: 510px !important;
                    margin-left: 0px !important;
                    gap: 10px !important;
                    height: auto !important;
                    padding-bottom: 4px !important;

                    p{
                        padding-top: 40px !important;
                        gap: 6px;
                        display: flex;
                        flex-direction: column;
                        width: calc(98% - 30px);
                    }


                    .roleBtn{
                        margin-top: 3px;
                    }
                    
                }



                
            }
        }


        

       
    }

 


    
}

@media (min-width : 320px) and (max-width: 619px) {
     .subservicesContainer{
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;

        display: flex;
        flex-direction: row;
        padding-bottom: 20px !important;
        margin-right: 10px !important;
        margin-left: 10px !important;
        gap: 35px !important;
        // padding-left: auto;
        // padding-right: auto;

        .subservicesLeft{
            display: none !important;
               .subcontainer1{
                display: none !important;
            }
            // .container{
            //     display: none;
            //     .box{
            //         width: 89px !important;
            //         height: 79px !important;
            //         margin-left: -10px;

            //         .boxleft{
            //             width: 30px !important;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             .icons{
            //                 width: 20px;
            //                 height: 20px;
            //             }
            //         }

            //         .boxright{
            //             width: 130px;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             p{
            //                 font-size: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

        .subservicesRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;

            .container2{
                margin-top: 20px !important;
                display: flex !important;
                align-items: center !important;
                height: auto !important;
                width: auto !important;
                padding-bottom:  20px !important;
                justify-content: center;
                padding-right: 50px !important;
                gap: 10px !important;
               

                .box{
                    display: flex;
                    flex-direction: column;
                    // padding-left:20px !important;
                    padding-right:90px !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    width: 340px !important;
                    margin-left: 20px !important;
                    gap: 10px !important;
                    height: auto !important;
                    padding-bottom: 4px !important;

                    p{
                        padding-top: 40px !important;
                        gap: 10px;
                        display: flex;
                        flex-direction: column;
                        width: calc(98% - 30px);
                    }


                    .roleBtn{
                        margin-top: 3px;
                    }
                    
                }



                
            }
        }

        

       
    }

 


    
}
