.network{
    width: 100%;
    height: 550px;

    .networkContainer{
        margin-left: 90px;
        margin-right: 90px;
        padding-top: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // position: relative;
        flex : 1;

        .networkTop{
            display: flex;
            flex : 0.5;
            flex-direction: column;
            align-items: center;

            .title{
                color : #817F7F;
            }
            .subtitle{
                color: #000E5E;
                font-size: 2.3rem;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv{
                display: flex;
                align-items: center;
                margin-right: 50px;
                justify-content: center;
                
                .desc{
                    color: #000000;
                    padding-top: 20px;
                    font-size: 1.2rem;
                    width: 100%;
    
                }
    
    
        }
        }

        .networkBottom{
            flex: 0.5;
            display: flex;
            height: 200px;
            // position: absolute;
            // padding-top: 100px;
            padding-left: 10%;
        }

    }

}


@media (min-width: 720px) and (max-width: 1600px) {
    
}
@media (min-width: 320px) and (max-width: 720px) {
   .network{
    height: 950px !important;
    padding-bottom: 20px !important;

    .networkContainer{
        margin-left: 20px !important;
        margin-right: 20px !important;
        padding-top: 20px;
        height: 100%;
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        position: relative;
        flex : 1;

        .networkTop{
            display: flex;
            flex : 0.5;
            justify-content: center !important;
            flex-direction: column;
            align-items: center;

            .title{
                color : #817F7F;
            }
            .subtitle{
                color: #000E5E;
                font-size: 2.3rem;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv{
                display: flex;
                align-items: center;
                margin-right: 0px;
                justify-content: center;
                
                .desc{
                    color: #000000;
                    padding-top: 20px;
                    font-size: 1.2rem;
                    width: 100%;
    
                }
    
    
        }
        }

        .networkBottom{
            flex: 0.5;
            display: flex;
            height: 400px !important;
            // position: absolute;
            padding-top: 200px;
            padding-left: 10%;
        }

    }



   } 
}