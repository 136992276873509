.digital{
    width: 100vw;
    height: auto;
    
    .digitalContainer{
        padding-top: 60px;
        padding-bottom: 90px;
        margin-left: 150px;
        margin-right: 150px;
        // margin-left: 10%;
        // padding-right: 20%;
        // margin-left: 150px;
        // margin-right: 150px;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        // flex: 1;

        .digitalLeft{
            display: flex;
            // margin-top: 10px;
            // flex : 0.5;
            // align-items: center;
            // justify-content: flex-start;

            .leftImg{
                width: 585px;
                height: 695px;
                z-index: 1;
                // position: relative;
    
                object-position: 200 , 1195;
    
            }
            
        }

        .digitalRight{
            display: flex;
            align-items: flex-start;
            // margin-top: 10px;
            padding-left: 115px;
            // width: 100%;
            // flex : 0.5;
            flex-direction: column;

            .title{
                color : #817F7F;
            }
            .subtitle{
                display: flex;
                width: 90%;
                color: #020E5A;
                font-size: 2.9rem;
                line-height: 52px;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv{
                display: flex;
                align-items: start;
                // justify-content: flex-start;
                
                .desc{
                    text-align: start;
                    padding-top: 20px;
                    line-height: 33px;
                    font-size: 1.2rem;
                    width: 95%;
                    display: flex;

                }


            }

            .descriptionImg{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 843px;
                height: 226px;
                margin-top: 390px;
                // object-position: 672px 1640px;
                background-color: #477DFF;
                // position: sticky;
                position: absolute;
                z-index: 2;
                margin-left: -360px;
                gap: 60px;
                // left: 950px;
                line-height: 0.2;
    
                .descTitle{
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    color: #FFFFFF;
                    letter-spacing: 4px;
                    font-weight: normal;
                    
                }
                
                .descNo{
                    // font-weight: bold;
                    text-transform: uppercase;
                    font-size: 4.7rem;
                    color: #FFFFFF;
    
    
                }
                
                
                
                
            }
    

        }


    }
}

// @media (min-width : 960px) {
//     .digital .digitalContainer{
//         margin-top: 40px;
//         padding-left: auto;
//         padding-right: auto;

//         .digitalRight{
//             padding-left: auto;

//             .subtitle{
//                 width: auto;
//             }

//             .descriptionDiv{
//                 width: auto;
//             }
//             // .descriptionImg{
//             //     position: sticky;
//             //     margin-top: 50px;
//             //     gap: auto;
//             //     position: auto;
//             // }
//         }

//     }
    
// }

@media (min-width : 850px) and (max-width: 1600px) {
    .digital .digitalContainer{
        overflow-x: hidden;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        
        
        padding-left: auto;
        padding-right: auto;
        align-items: center;

        .digitalLeft{
            
            .leftImg{
                width: auto;
                height: auto;
            }
        }

       

        .digitalRight{
            padding-left: 50px;

            .subtitle{
                width: 100%;
                font-size: 2.2rem;
            }

            .descriptionDiv{
                .desc{
                    line-height: 23px;
                    font-size: 1rem;
                    text-align: justify;
                }
            }

            .descriptionImg{
                display: none;
                width: 543px;
                height: 120px;
                line-height: 0.1;
                // gap: 20px;

                .descTitle{
                    font-size: 1rem;

                }

                .descNo{
                    font-size: 2rem;
                }
            }
        }

        // .descriptionImg{
        //     margin-top: 400px;
        //     right: auto;
        //     margin-top: auto;
        //     gap: auto;
        //     position: auto;
        // }
    }
    
}

@media (max-width : 850px) {
    .digital .digitalContainer{
        overflow-x: hidden;
        display: flex;
        flex-direction: column-reverse !important;
        margin-right: 15px !important;
        margin-left: 15px !important;
        align-items: center;

        .digitalLeft{
            .leftImg{
                width: 338px !important;
                height: 350px !important;
            }
        }

        .digitalRight{
            display: flex;
            flex-direction: column !important;
            padding-left: 10px;
            .subtitle{
                width: 100%;
                font-size: 18px !important;
                font-weight: bold;
                line-height: 22px;
            }
            .divBelowsubtitle{
                margin-left: 40%;
            }
            .descriptionDiv{
                margin-right: 0px;
                .desc{
                    font-family: 'Poppins';
                    font-size: 18px !important;
                    display: flex;
                    line-height: 20px;
                    width: auto !important;
                    text-align: center !important;
                }
            }

        }

        .descriptionImg{
            display: none !important;
            // right: auto;
            // margin-top: auto;
            // gap: auto;
            // position: auto;
        }
    }
    
}