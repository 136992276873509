.leadershipDetails {
  width: 100%;
  height: auto;
  padding-bottom: 45px;

  .leadershipDetailsContainer {
    margin-left: 90px;
    margin-right: 90px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .leadershipDetailsTop {
      display: flex;
      // flex: 0.6;
      flex-direction: column;

      .headlines {
        // flex: 0.2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          font-size: 24px !important;
          color: #817f7f !important;
        }

        .subtitle {
          font-size: 2.5rem;
          font-weight: bold;
          color: #020e5a;
        }
        .divBelowsubtitle {
          margin-top: 30px;
          width: 76px;
          height: 2px;
          background-color: #c13895;
        }
      }

      .desc {
        width: 100%;
        flex: 0.6;
        display: flex;
        flex-direction: row;
        text-align: start;
        margin-top: 40px;

        .descImg {
          flex: 0.3;
          padding-left: 350px;

          .leader {
            width: 260px;
            height: 350px;
          }
        }

        .descList {
          flex: 0.3;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // font-size: 1.2rem;
          text-align: start;
          padding-left: 30px;

          .title {
            text-transform: uppercase;
            letter-spacing: 2px;
            color: #317fff;
            margin-bottom: -30px;
          }

          .desc {
            text-transform: uppercase;
            // letter-spacing: 2px;
            font-size: 1rem;
          }
          
          .paragraph {
            width: 550px;
            padding-top: 10px;
            font-size: 0.9rem;
          }
        }
      }
    }

    .leadershipDetailsMiddle {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0.1;
      padding-top: 30px;

      .barImg {
        width: 520px;
        height: 1px;
      }
    }

    .leadershipBottom {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0.3;
      height: auto;

      .carousalContainer {
        margin: 20px;

        
        
        // .selectedItem{
            
            // }
            
            .carousal {
              padding-top: 60px;
            display: flex;
          position: relative;
          align-items: center;


          .carousal__images {
            display: flex;
            max-width: 100%;
            overflow-x: hidden;
            cursor: pointer;

            .carousalItems {
              display: flex;
              flex-direction: column;
              position: relative;
            //   align-items: center;
              text-align: start;
            //   justify-content: center;

              .carousal__image {
                margin-right: 10px;
                height: 190px;
                min-width: 150px;
                border: 3px solid #ffa70000;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
              }

              h5{
                position: absolute;
                margin-left: 20px;
                padding-top: 150px;
                text-transform: uppercase;
                font-size: 0.7rem;
                color: white
              }

              .imagedesc{
                position: absolute;
                margin-left: 20px;
                padding-top: 155px;
                // text-transform: uppercase;
                font-size: 0.5rem;
                color: #5BF0D9
              }



            }

            .carousel__image-selected {
              border: 3px solid #ffa700 !important;
            }

            .carousal_buttons {
              position: absolute;
              top: 43%;

              .carousel__button-left {
                position: relative;
                right: 53px;
                width: 21px;
                height: 38px;
              }
              
              .carousel__button-right {
                position: relative;
                left: 970px;
                width: 21px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 760px) and (max-width:1600px) {
  .leadershipDetails{
    .leadershipDetailsContainer{
      margin-left: 20px !important;
      margin-right: 20px !important;
      align-items: center !important;

      .leadershipDetailsTop{
      
        .desc{
          margin-top: 30px !important;

          .descImg{
            padding-left: 20px !important;

            .leader{
              width: 100px !important;
              height: 120px !important;
              border-radius: 10px;
            }
          }
          .descList{
            padding-left: 10px !important;

            .paragraph{
              width: 420px !important;
              font-size: 1.3rem !important;
              text-align: justify;
            }
          }
        }
      }

      .leadershipDetailsMiddle{
        padding-top: 20px !important;

        .barImg{
          width: 320px !important;
        }
      }

      .leadershipBottom{
        display: flex;
        align-items: center;
      justify-content: center;

        .carousalContainer{
          margin: 10px !important;
          
          .carousal{
            display: flex;
            padding-right: 55px !important;
            align-items: center;
            padding-top: 20px !important;
            
            .carousal__images{
              max-width: 100% !important;
              overflow-x: hidden;
              cursor: pointer;

              .carousalItems{
                display: flex;
                gap: 5px !important;
                max-width: 120px !important;

                .carousal__image{
                  height: 92px !important;
                  width: 80px !important;
                  object-fit: contain;
                  border-radius: 5px;
                  // background-position: start;
                  // max-width: 90px !important;
                  border: 1px solid #ffa70000;
    
                }
  
                h5{
                  display: none;
                }
  
                .imagedesc{
                  display: none;
                }
              }
  

              .carousel__image-selected {
                border: 3px solid #ffa700 !important;
              }
  
              .carousal_buttons {
                display: none !important;
                position: absolute;
                // top: 43%;
  
                .carousel__button-left {
                  display: none;
                  position: relative;
                  right: 53px;
                  width: 21px;
                  height: 38px;
                }
                
                .carousel__button-right {
                  display: none;
                  position: relative;
                  left: 970px;
                  width: 21px;
                  height: 38px;
                }
              }


            }


          }

          

        }
      }
    }
  }
 }




 @media (min-width: 320px) and (max-width:760px) {
  .leadershipDetails{
    .leadershipDetailsContainer{
      margin-left: 20px !important;
      margin-right: 20px !important;
      align-items: center !important;

      .leadershipDetailsTop{
        .desc{
          margin-top: 30px !important;

          .descImg{
            padding-left: 20px !important;

            .leader{
              width: 100px !important;
              height: 120px !important;
              border-radius: 10px;
            }
          }
          .descList{
            padding-left: 10px !important;

            .paragraph{
              width: 220px !important;
              font-size: 0.8rem !important;
              text-align: justify;
            }
          }
        }
      }

      .leadershipDetailsMiddle{
        padding-top: 20px !important;

        .barImg{
          width: 320px !important;
        }
      }

      .leadershipBottom{
        display: flex;
        align-items: center;
      justify-content: center;

        .carousalContainer{
          margin: 10px !important;
          
          .carousal{
            display: flex;
            padding-right: 55px !important;
            align-items: center;
            padding-top: 20px !important;
            
            .carousal__images{
              max-width: 100% !important;
              overflow-x: hidden;
              cursor: pointer;

              .carousalItems{
                display: flex;
                gap: 5px !important;
                max-width: 80px !important;

                .carousal__image{
                  height: 52px !important;
                  width: 50px !important;
                  object-fit: contain;
                  border: none;
                  // background-position: start;
                  // max-width: 90px !important;
                  border: 1px solid #ffa70000;
    
                }
  
                h5{
                  display: none;
                }
  
                .imagedesc{
                  display: none;
                }
              }
  

              .carousel__image-selected {
                border: 3px solid #ffa700 !important;
              }
  
              .carousal_buttons {
                display: none !important;
                position: absolute;
                // top: 43%;
  
                .carousel__button-left {
                  display: none;
                  position: relative;
                  right: 53px;
                  width: 21px;
                  height: 38px;
                }
                
                .carousel__button-right {
                  display: none;
                  position: relative;
                  left: 970px;
                  width: 21px;
                  height: 38px;
                }
              }


            }


          }

          

        }
      }
    }
  }
 }
