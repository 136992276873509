.homeComponent{
    margin-top: -85px;
    width: 100% !important;
    height: 100% !important;
    z-index: 0;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    scroll-behavior: smooth;
    
  
    
}

