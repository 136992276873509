.homeCareers{
     width: 100%;
     overflow-x: hidden;
     height: auto;
     padding-bottom: 55px;
     background-color: #FFFFFF;
     display: flex;
    flex-direction: row;
    flex: 1;

    .homeCareersContainer{
        padding-left: 150px;
        padding-right: 150px;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        // flex: 1;
    }

    .homecareersLeft{
        display: flex;
        margin-top: 80px;
        // flex: 0.6;

        .leftImg{
            width: 605px;
            height: 665px;
            z-index: 1;
            // position: relative;

            object-position: 200 , 1195;

        }


    }
    .homecareersRight{
        display: flex;
        align-items: flex-start;
        margin-top: 80px;
        padding-left: 115px;
        // flex : 0.4;
        flex-direction: column;
        width: 100%;

        .title{
            color : #817F7F;
        }
        .subtitle{
            color: #020E5A;
            font-size: 2.9rem;
            line-height: 62px;
            font-weight: bold;
        }
        .divBelowsubtitle{
            margin-top: 30px;
            width: 76px;
            height: 2px;
            background-color: #C13895;
        }
        .descriptionDiv{
            display: flex;
            align-items: start;
            // justify-content: flex-start;
            
            .desc{
                text-align: start;
                padding-right: 40px;
                padding-top: 20px;
                font-size: 1.2rem;
                width: 95%;
                display: flex;
                flex-direction: column;
                // justify-content: stretch;

            }


        }

        .roleBtn{
            width:260px ;
             height:64px;
             background-color: #D22599;
             color: #FFFF;
             border: none;
             font-size: 1.2rem;
            border-radius:70px;
            margin-top:30px ;
            border:none;
            cursor: pointer;
        }
        
        
    }
}

@media (min-width: 710px) and (max-width : 1400px) {
    .homeCareers .homeCareersContainer{
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 20px;
        margin-right: 20px;
        align-items: center;

        .homecareersLeft{
            // padding-left: 120px;
        // padding-right: 120px;
            .leftImg{
                object-fit: contain;
                width: 450px;
                height: 420px;
            }
        }

        .homecareersRight{
          width: 90%
        }
    }
   
}


@media (max-width: 700px){
    .homeCareers .homeCareersContainer{
        overflow-x: hidden;
        display: flex;
        flex-direction: column-reverse !important;
        padding-top: 20px;
        padding-left: 15px !important;
        padding-right: 15px !important;
        // margin-right: 10px !important;
        // margin-left: 10px !important;
        align-items: center;
        justify-content: center;
        gap: 10px !important;

        .homecareersLeft{
           align-items: center !important;

            .leftImg{
                object-fit: contain;
                width: 297px !important;
                height: 315px !important;
            }
        }

        .homecareersRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;
            padding-left: 0px !important;
            // padding-left: 20px;
            .subtitle{
                font-size: 18px !important;
            }

            .divBelowsubtitle{
                margin-top: 10px;
            width: 76px;
            height: 2px;
            background-color: #C13895;
            }
            
            .descriptionDiv{
                margin-right: 0px;
                
                .desc{
                    width: 100%;
                    padding-left: 20px !important;
                    display: flex;
                    font-family: 'Poppins';
                    font-size: 18px !important;
                    justify-content: stretch;
                    display: flex;
                    line-height: 22px;
                    width: auto !important;
                    text-align: center !important;
                }
            }

            .roleBtn{
                width:196px ;
                 height: 50px !important;
                 background-color: #D22599;
                 color: #FFFF;
                 border: none;
                 font-size: 1.2rem;
                border-radius:70px;
                margin-top:30px ;
                border:none;
                cursor: pointer;
            }
        }


    }
}