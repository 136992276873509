.culture{
    width: 100%;
    height: 800px;
    background-color: #010B46;

    .cultureContainer{
        margin-left: 150px;
        margin-right: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex : 1;

        .cultureTop{
            display: flex;
            flex : 0.5;
            flex-direction: column;
            align-items: center;

            .title{
                color : #817F7F;
            }
            .subtitle{
                padding-top: 10px;
                color: #FFFF;
                font-size: 2.5rem;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 50px;
                justify-content: center;
                text-align: justify;
                
                .desc{
                    color: #FFFF;
                    padding-top: 20px;
                    font-size: 1rem;
                    width: 65%;
    
                }
    
    
        }
        }

    .cultureBottom{
        flex: 0.5;
        display: flex;
        flex-direction: row;
        height: 200px;
        position: absolute;
        padding-top: 390px;

        .bottomContainer{

            padding-left: 20px;
            padding-right: 90px;
            display: flex;
            align-self: flex-start;
            flex-direction: column;
            text-align: start;
            line-height: 1.5;

            .no{
                margin-top: 50px;
                font-size: 1rem;
                color: #FFFFFF;
                text-transform: uppercase;
            }
            .desc{
                padding-top: 5px;
                flex-direction: row;
                display: flex;
                color: #FFFFFF;
                font-size: 1rem;
                font-weight: bold;
            }
    }
    .lineContainer{
        width: 0;
        height: 109px;
        display: flex;
        flex-direction: row;
        padding-top: 80px;
    }

}
    }
}

@media (min-width : 760px) and (max-width: 1500px) {
  .culture{
    width: 100%;
    height: 950px !important;

     .cultureContainer{
        margin-left: 30px !important;
        margin-right: 30px !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex : 1;

        .cultureTop{
            display: flex;
            flex : 0.5;
            flex-direction: column;
            align-items: center;

            .title{
                color : #817F7F;
            }
            .subtitle{
                padding-top: 10px;
                color: #FFFF;
                font-size: 2.5rem;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 50px;
                justify-content: center;
                text-align: justify;
                
                .desc{
                    color: #FFFF;
                    padding-top: 20px;
                    font-size: 1rem;
                    width: 65%;
    
                }
    
    
        }
        }

    .cultureBottom{
        flex: 0.5;
        display: flex;
        flex-direction: row;
        height: 200px;
        position: absolute;
        padding-top: 490px;

        .bottomContainer{

            padding-left: 20px;
            padding-right: 90px;
            display: flex;
            align-self: flex-start;
            flex-direction: column;
            text-align: start;
            line-height: 1.5;

            .no{
                margin-top: 50px;
                font-size: 1rem;
                color: #FFFFFF;
                text-transform: uppercase;
            }
            .desc{
                max-width: 190px !important;
                padding-top: 5px;
                flex-direction: row;
                display: flex;
                color: #FFFFFF;
                font-size: 0.8rem;
                font-weight: bold;
            }
    }
    .lineContainer{
        width: 0;
        height: 109px;
        display: flex;
        flex-direction: row;
        padding-top: 80px;
    }

}
    }
  }
    
}

@media (min-width : 320px) and (max-width: 759px) {
  .culture{
    width: 100%;
    height: 750px !important;

     .cultureContainer{
        margin-left: 30px !important;
        margin-right: 30px !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        gap: 30px;
        // flex : 1;

        .cultureTop{
            display: flex;
            flex : 0.5;
            flex-direction: column;
            align-items: center;

            .title{
                color : #817F7F;
            }
            .subtitle{
                padding-top: 10px;
                color: #FFFF;
                font-size: 1.5rem;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 10px !important;
                width: 56px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 20px;
                justify-content: center;
                text-align: justify;
                
                .desc{
                    color: #FFFF;
                    padding-top: 10px;
                    font-size: 1rem;
                    width: 85%;
    
                }
    
    
        }
        }

    .cultureBottom{
        flex: 0.5;
        display: flex;
        flex-direction: row;
        height: 200px;
        position: absolute;
        padding-top: 590px;
        display: none !important;

        .bottomContainer{
            padding-left: 20px;
            padding-right: 90px;
            display: flex;
            align-self: flex-start;
            flex-direction: column;
            text-align: start;
            line-height: 1.5;

            .no{
                margin-top: 50px;
                font-size: 1rem;
                color: #FFFFFF;
                text-transform: uppercase;
            }
            .desc{
                
                padding-top: 5px;
                flex-direction: column;
                display: flex;
                color: #FFFFFF;
                font-size: 1rem;
                font-weight: bold;
            }
    }
    .lineContainer{
        display: none !important;
        width: 0;
        height: 109px;
        display: flex;
        flex-direction: row;
        padding-top: 80px;
    }

}
    }
  }
    
}