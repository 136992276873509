.contactusContainer{
    padding-top: 30px;
    width: 100%;
    height: 760px;
    margin-left: 150px;
    margin-right: 150px;
    display: flex;
    flex-direction: row;
    flex: 1;

    .contactusLeft{
        display: flex;
        flex: 0.5;
        flex-direction: column;
        text-align: start;

        .subtitle {
            font-size: 2.5rem;
            font-weight: bold;
            color: #020e5a;
          }
          .divBelowsubtitle {
            margin-top: 30px;
            width: 76px;
            height: 2px;
            background-color: #c13895;
          }

          .contactusDetails{
            display: flex;
            flex-direction: column;

            .imgs{
                width: 30px;
                height: 30px;
            }

            .details{
                padding-top: 15px;
                font-size: 1.0rem;
                padding-left: 40px;
            }
          }

          .breaker{
            padding-top: 30px;
          }

          .social{
            justify-content: start;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            align-items: flex-start;
            

          }


    }

    .contactusRight{
        display: flex;
        flex: 0.5;
        padding-right: 140px;

        .rightContainer{
            padding-top: 30px;
            padding-left: 10px;
            padding-right: 10px;
            width: 80%;
            height: 90%;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .formItems{
              display: flex;
              flex-direction: row;
              // align-items: center;
              justify-content: center;
              
              .inputFields{
                text-transform: uppercase;
                margin: 10px;
                padding-left: 5px;
                width: 290px;
                height: 33px;
                border: 0.5px solid lightgray;
                font-size: 0.8rem;
              }

              .select{
                text-transform: uppercase;
                margin: 8px;
                padding-left: 0px;
                width: 290px;
                height: 33px;
                font-size: 0.8rem;
                border-radius: 0%;
              }

              
            }

            .helpComp{
              display: flex;
              padding-top: 50px;
              padding-left: 10%;
              font-weight: bold;
              text-align: start;
              flex-direction: column;

              .helpSelect{
                padding-top: 20px;
                padding-left: 0px;
                margin-top: 3px;
                width: 425px;
                height: 33px;
                font-size: 0.8rem;
                border-radius: 0%;
                background-color: white;
              }
            }

            .checkboxComp{
              padding-top: 90px;
              padding-left: 9%;
              display: flex;
              flex-direction: column;

              .terms{
                display: flex;
                flex-direction: row;
                margin: 10px;
              }
            }

            .roleBtn {
              margin-top: 40px;
              margin-left: 9%;
              // margin-top: -150px;
              width: 123px;
              height: 34px;
              background-color: #d22599;
              font-size: 1.2rem;
              color: #ffff;
              border: none;
              border-radius: 70px;
              margin-top: 30px;
              border: none;
              cursor: pointer;
            }

            
        }

        
    }
}

@media (min-width : 760px) and (max-width: 1600px) {
  .contactusContainer{
    padding-top: 20px !important;
    height: auto !important;
    padding-bottom: 20px !important;
    margin-left: 20px !important;;
    margin-right: 20px !important;;
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;

    
    .contactusLeft{
      padding-left: 10px !important;
      padding-right: 10px !important;

      .imgs{
        width: 10px;
        height: 10px;
      }

      .details{
        padding-top: 10px;

      }
    }

    .contactusRight{
      padding-right: 20px !important;
      margin-top: 20px !important;

         .rightContainer{
            padding-top: 20px !important;
            padding-left: 10px;
            padding-right: 10px;
            width: 90%;
            height: 99%;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .formItems{
              display: flex;
              flex-direction: row;
              padding-left: 5px !important;
              padding-right: 5px !important;

              // align-items: center;
              justify-content: center;
              
              .inputFields{
                text-transform: uppercase;
                margin: 10px;
                padding-left: 5px;
                width: 230px;
                height: 33px;
                border: 0.5px solid lightgray;
                font-size: 0.8rem;
              }

              .select{
                text-transform: uppercase;
                margin: 8px;
                padding-left: 0px;
                width: 290px;
                height: 33px;
                font-size: 0.8rem;
                border-radius: 0%;
              }

              
            }

            .helpComp{
              display: flex;
              padding-top: 50px;
              padding-left: 10%;
              font-weight: bold;
              text-align: start;
              flex-direction: column;

              .helpSelect{
                padding-top: 20px;
                padding-left: 0px;
                margin-top: 3px;
                width: 290px;
                height: 33px;
                font-size: 0.8rem;
                border-radius: 0%;
                background-color: white;
              }
            }

            .checkboxComp{
              padding-top: 90px;
              padding-left: 9%;
              display: flex;
              flex-direction: column;

              .terms{
                display: flex;
                flex-direction: row;
                margin: 10px;
              }
            }

            .roleBtn {
              margin-left: 11%;
              // margin-top: -150px;
              width: 123px;
              height: 34px;
              background-color: #d22599;
              font-size: 1.2rem;
              color: #ffff;
              border: none;
              border-radius: 70px;
              margin-top: 30px;
              margin-bottom: 20px !important;
              border: none;
              cursor: pointer;
            }

            
        }
    }
    
  }
    
}

@media (min-width : 320px) and (max-width: 759px) {
  .contactusContainer{
     padding-top: 10px !important;
    height: auto !important;
    padding-bottom: 20px !important;
    margin-left: 20px !important;;
    margin-right: 20px !important;;
    display: flex !important;
    flex-direction: column;

    .contactusLeft{
      padding-left: 10px !important;
      padding-right: 10px !important;

      .imgs{
        width: 10px;
        height: 10px;
      }

      .details{
        padding-top: 10px;

      }
    }

    .contactusRight{
      padding-right: 20px !important;
      margin-top: 20px !important;

         .rightContainer{
            padding-top: 20px !important;
            padding-left: 10px;
            padding-right: 10px;
            width: 90%;
            height: 99%;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background-color: #FFFFFF;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .formItems{
              display: flex;
              flex-direction: row;
              padding-left: 5px !important;
              padding-right: 5px !important;

              // align-items: center;
              justify-content: center;
              
              .inputFields{
                text-transform: uppercase;
                margin: 10px;
                padding-left: 5px;
                width: 130px !important;
                height: 33px;
                border: 0.5px solid lightgray;
                font-size: 0.8rem;
              }

              .select{
                text-transform: uppercase;
                margin: 8px;
                padding-left: 0px;
                width: 290px;
                height: 33px;
                font-size: 0.8rem;
                border-radius: 0%;
              }

              
            }

            .helpComp{
              display: flex;
              padding-top: 50px;
              padding-left: 10%;
              font-weight: bold;
              text-align: start;
              flex-direction: column;

              .helpSelect{
                padding-top: 20px;
                padding-left: 0px;
                margin-top: 3px;
                width: 290px;
                height: 33px;
                font-size: 0.8rem;
                border-radius: 0%;
                background-color: white;
              }
            }

            .checkboxComp{
              padding-top: 90px;
              padding-left: 9%;
              display: flex;
              flex-direction: column;

              .terms{
                display: flex;
                flex-direction: row;
                margin: 10px;
              }
            }

            .roleBtn {
              margin-left: 11%;
              // margin-top: -150px;
              width: 123px;
              height: 34px;
              background-color: #d22599;
              font-size: 1.2rem;
              color: #ffff;
              border: none;
              border-radius: 70px;
              margin-top: 30px;
              margin-bottom: 20px !important;
              border: none;
              cursor: pointer;
            }

            
        }
    }
  }
    
}