.subservicesdesc1{
    width: 100%;
    // position: relative;
    
    
    .subservicesContainer1{
        height: auto;
        padding-bottom: 20px;
        // width: 100%;
        display: flex;
        flex-direction: row;
        gap: 40px;
        // gap: 40px;
        // flex: 1;
        margin-left: 150px;
        margin-right: 150px;
        // border: 1px solid black;
        // padding-top: 30px;
        
        .subservicesLeft{
            
            display: flex;
            
            
            // flex: 0.3;
            
            .container{

                width: 100%;
                padding-top: 10px;
                // width: 90%;
                // height: 90%;
                // border-radius: 1px solid black;
                .box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;    
                    margin-top: 60px;
                    width: 379px;
                    height: 205px;
                    margin-left: -30px;
                    // border: 1px solid black;
                    
                    .boxleft{
                        display: flex;
                        width: 150px;
                        height: 100%;
                        padding-top: 130px;
                        padding-left: 30px;
    
                        .icons{
                            width: 65px;
                            height: 60px;
                        }
                    }
                    
                    .boxright{
                        width: 230px;
                        // background-color: #0623D9;
                        display: flex;
                        height: 100%;
                        padding-top: 60px;
                        padding-left: 30px;
    
                        p{
                            flex-direction: column;
                            font-weight: bold;
                            // padding-left: 15px;
                            padding-right: 25px;
                            font-size: 1.5rem;
                            text-transform: uppercase;
                            color: #FFFF;
                            
                        }
                    }
                    
                    
                }
            }
            
        }
        
        .subservicesRight{
            display: flex;
            padding-right: 690px;
            // flex: 0.7;
            width: 100%;
            
            // background-color: red;
            .container1{
                display: flex;
                flex-direction: column;
                padding-top: 55px;
                border-radius: 1px solid black;
                // width: 350px;
                // width: auto;
                
                .box1{
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    // justify-content: center;    
                    margin-top: 10px;
                    width: 1100px ;
                    height: 255px;
                    margin-left: 50px;
                    text-align: start;
                    padding: 2px;
                    // border: 1px solid;

                        .line{
                        // margin-left: 730px;
                        width: 100%;
                        height: 3px;
                    }
        
                    p{
                        width: 100%;
                        // margin-left: 730px;
                        font-size: 0.9rem;
        
                    }

                    .roleBtn {
                        margin-top: 20px;
                        // margin-left: 3%;
                        // margin-top: -150px;
                        width: 163px;
                        height: 38px;
                        background-color: #d22599;
                        font-size: 1.2rem;
                        color: #ffff;
                        border: none;
                        border-radius: 70px;
                        border: none;
                        cursor: pointer;
                      }

                    }
                }
    
            }
        // }
        
    }

    
}

@media (min-width : 900px) and (max-width: 1500px) {
    .subservicesdesc1 .subservicesContainer1{
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px !important;
        gap: 35px !important;
        // padding-left: auto;
        // padding-right: auto;

        .subservicesLeft{
            display: none !important;
            // .container{
            //     display: none;
            //     .box{
            //         width: 89px !important;
            //         height: 79px !important;
            //         margin-left: -10px;

            //         .boxleft{
            //             width: 30px !important;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             .icons{
            //                 width: 20px;
            //                 height: 20px;
            //             }
            //         }

            //         .boxright{
            //             width: 130px;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             p{
            //                 font-size: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

        .subservicesRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;

            .container1{
                
                margin-top: 50px !important;
                display: flex !important;
                align-items: center !important;
                width: auto !important;
                justify-content: center;
                gap: 70px !important;
               

                .box1{
                    
                    padding-left:10px !important;
                    padding-right:10px !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    width: 950px !important;

                    p{
                        font-size: 1.4rem;
                    }

                    .roleBtn{
                        margin-top: 3px;
                        font-size: 1rem;
                    }


                    
                }



                
            }
        }

        

       
    }

 


    
}

@media (min-width : 620px) and (max-width: 900px) {
    .subservicesdesc1 .subservicesContainer1{
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;
        display: flex;
        flex-direction: row;
        padding-bottom: 20px !important;
        gap: 35px !important;
        // padding-left: auto;
        // padding-right: auto;

        .subservicesLeft{
            display: none !important;
            // .container{
            //     display: none;
            //     .box{
            //         width: 89px !important;
            //         height: 79px !important;
            //         margin-left: -10px;

            //         .boxleft{
            //             width: 30px !important;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             .icons{
            //                 width: 20px;
            //                 height: 20px;
            //             }
            //         }

            //         .boxright{
            //             width: 130px;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             p{
            //                 font-size: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

        .subservicesRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;

            .container1{
                
                margin-top: 50px !important;
                display: flex !important;
                align-items: center !important;
                width: auto !important;
                justify-content: center;
                gap: 70px !important;
               

                .box1{
                    
                    padding-left:10px !important;
                    padding-right:10px !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    width: 750px !important;

                    p{
                        font-size: 1.4rem;
                    }

                    .roleBtn{
                        margin-top: 3px;
                        font-size: 1rem;
                    }


                    
                }



                
            }
        }

        

       
    }

 


    
}

@media (min-width : 320px) and (max-width: 619px) {
    .subservicesdesc1 .subservicesContainer1{
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;

        display: flex;
        flex-direction: row;
        padding-bottom: 20px !important;
        gap: 35px !important;
        // padding-left: auto;
        // padding-right: auto;

        .subservicesLeft{
            display: none !important;
            // .container{
            //     display: none;
            //     .box{
            //         width: 89px !important;
            //         height: 79px !important;
            //         margin-left: -10px;

            //         .boxleft{
            //             width: 30px !important;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             .icons{
            //                 width: 20px;
            //                 height: 20px;
            //             }
            //         }

            //         .boxright{
            //             width: 130px;
            //             padding-top: 10px;
            //             padding-left: 5px;

            //             p{
            //                 font-size: 0.5rem;
            //             }
            //         }
            //     }
            // }
        }

        .subservicesRight{
            display: flex;
            align-items: center !important;
            justify-content: center !important;

            .container1{
                margin-top: 30px !important;
                display: flex !important;
                align-items: center !important;
                width: auto !important;
                justify-content: center;
                gap: 20px !important;
               

                .box1{
                    // padding-left:20px !important;
                    padding-right:30px !important;
                    display: flex;
                    align-items: center !important;
                    justify-content: center !important;
                    width: 370px !important;

                    .roleBtn{
                        margin-top: 3px;
                    }
                    
                }



                
            }
        }

        

       
    }

 


    
}





// @media (max-width : 510px){
//         .subservicesdesc .subservicesContainer{
//         margin-left: 20px;
//         margin-right: 20px;
//         display: flex;
//         align-items: center !important;
//         justify-content: center !important;
//         width: 100% !important;
//         // padding-left: auto;
//         // padding-right: auto;

//         .subservicesLeft{
//             display: none !important;
//             // .container{
//             //     .box{
//             //         width: 89px !important;
//             //         height: 79px !important;
//             //         margin-left: -10px;

//             //         .boxleft{
//             //             width: 30px !important;
//             //             padding-top: 10px;
//             //             padding-left: 5px;

//             //             .icons{
//             //                 width: 20px;
//             //                 height: 20px;
//             //             }
//             //         }

//             //         .boxright{
//             //             width: 130px;
//             //             padding-top: 10px;
//             //             padding-left: 5px;

//             //             p{
//             //                 font-size: 0.5rem;
//             //             }
//             //         }
//             //     }
//             // }
//         }

//         .subservicesRight{
//             display: flex;
//             flex-direction: column;
//             align-items: center !important;
//             justify-content: center !important;
            

//             .container1{
//                 padding-top: 10px;
//                 align-items: center;
//                 justify-content: center;
//                 gap: 20px;

//                 .box1{
//                     width: 80px !important;
//                     margin-left: 4px !important;
//                 }

                
//             }
//         }

        

       
//     }

// }