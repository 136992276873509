.bannercomponent {
  width: 100%;
  height: 1080px;
  flex-direction: column;
  background-color: transparent;


  .bottom {
    display: flex;
    z-index: 0;
    // flex: 0.9;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // top: -80px;
    // position: absolute;

    .backgroundimg {
      height: 1050px;
      width: 120%;
      object-fit: cover;
      // position: relative;
    }
    .heading{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        top: 490px;
        font-size: 75px;
        color: #ffff;
        position: absolute;

        line-height: 3;
      }
  
      h4 {
        color: #ffff;
        top: 790px;
        font-size: 24px;
        position: absolute;
        color: #00ffb8;
      }
    }
    

    .icon {
      position: absolute;
      width: 26px;
      height: 40px;
      top: 920px;
    }
  }
}


@media (min-width : 850px) and (max-width: 1400px) {

  .bannercomponent{
    width: 100%;
    height: auto !important;
    padding-bottom: 40px !important;

    .bottom{
      display: flex;
      z-index: 0;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // top: -80px;
      // position: absolute;

      .backgroundimg{
        height: 750px !important;
        width: 100% !important;
        object-fit: cover;
      }
      
      .heading{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px !important;

        h1{
          display: flex;
          flex-direction: column;
          gap: 15px !important;
          top: 230px !important;
          position: absolute;
          font-size: 35px;
        }

       
  
        h4{
          top: 480px !important;
          font-size: 20px;
        }
        .icon{
          width: 20px;
          height: 30px;
          top: 360px !important;
        }
      }


    }
  }


  
}

@media (min-width : 320px) and (max-width: 850px) {

  .bannercomponent{
    width: 100%;
    padding-bottom: 40px !important;
    height: 500px !important;
    flex-direction: column;
  background-color: transparent;
  // position: relative;

    .bottom{
      display: flex;
      z-index: 0;
      gap: 10px !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // top: -80px;
      position: absolute;

      .backgroundimg{
        height: 450px !important;
        width: 100% !important;
        object-fit: cover;
        // position: relative;
      }

      .heading{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1{
          gap: 2px !important;
          top: 230px !important;
          position: absolute;
          font-size: 15px !important;
          line-height: 0.2px !important;
        }
  
        h4{
          top: 270px !important;
          font-size: 15px;
        }
      }


      .icon{
        width: 10px;
        height: 10px;
        top: 350px !important;
      }
    }
  }
  
}
