.App {
  // text-align: center;
  font-family: 'Poppins', sans-serif;
  
  // overflow-x: hidden;
  // overflow-y: hidden;
  scroll-behavior: smooth;
 
}


