.casestudy{
    width: 100%;
    height: 600px;
    background-color: #113245;
    position: relative;

    .casestudycommonContainer{
        margin-left: 190px;
    margin-right: 190px;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-top: 30px;
    }

    .descLeft {
      display: flex;
      flex: 0.5;
      align-items: flex-start;
      flex-direction: column;
      text-align: start;


      .title {
        font-size: 24px !important;
        color: #00B47E !important;
        text-transform: uppercase;
      }
      .subtitle {
        font-size: 2.5rem;
        font-weight: bold;
        color: #FFFF;
      }
      .divBelowsubtitle {
        margin-top: 30px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }

      .descriptionDiv {
        display: flex;
        align-items: start;
        flex-direction: column;
        text-align: start;
        margin-top: 50px;

        .casestudyTitle{
            font-size: 14px !important;
        color: #6BBCF8 !important;
        text-transform: uppercase;
        }

        .desc{
          font-size: 1rem;
          width: 75%;
          display: flex;
          color: #FFFF;
          margin-top: 20px;
        }
      }

      .roleBtn {
        // margin-top: -150px;
        width: 353px;
        height: 54px;
        background-color: #d22599;
        color: #ffff;
        border: none;
        border-radius: 70px;
        margin-top: 30px;
        border: none;
        cursor: pointer;
      }
    }

    .descRight {
      margin-left: 150px;
      display: flex;
      flex: 0.5;
      padding-top: 20px;
      // z-index: 1;
      
      

      .descbannerImg {
        width: 394px;
        height: 455px;
        z-index: 1;
      }
    }

  
}