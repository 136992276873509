.flipped {
  width: 100%;
  height: auto;
  padding-bottom: 20px ;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  flex: 1;

  .flippedContainer {
    margin-left: 190px;
    margin-right: 190px;
    // height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-top: 60px;

    .flippedLeft {
      display: flex;
      flex: 0.5;
      // align-items: center;
      // justify-content: center;
      // // position: relative;
      // flex-direction: column;

      .descbannerImg {
        width: 554px;
        height: 755px;
        // object-position: 0 , 2055 ;
        z-index: 0;
      }
    }

    .flippedRight {
      margin-left: 150px;
      display: flex;
      flex: 0.5;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-size: 24px !important;
        color: #817f7f !important;
      }
      .subtitle {
        font-size: 2.5rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 30px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }

      .descriptionDiv {
        display: flex;
        align-items: start;
        flex-direction: column;
        text-align: start;

        desc {
          padding-top: 20px;
          font-size: 1rem;
          width: 75%;
          display: flex;
        }
      }

      .roleBtn {
        // margin-left: 30%;
        width: 353px;
        height: 54px;
        background-color: #d22599;
        
        color: #ffff;
        font-size: 1.2rem;
        border: none;
        border-radius: 70px;
        margin-top: 70px;
        border: none;
        cursor: pointer;
      }
    }
  }
}


@media (min-width : 850px) and (max-width : 1600px){
  .flipped{
    height: auto !important;
    padding-bottom: 20px;
    .flippedContainer{
      padding-top: 20px !important;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      flex-direction: column!important;

      .flippedRight{
        margin-left: 20px !important;
        .divBelowsubtitle{
          margin-top: 5px;
        }

        .roleBtn {
        // margin-top: -150px;
        width: 193px !important;
        height: 34px !important;
        background-color: #d22599;
        color: #ffff;
        border: none;
        border-radius: 30px;
        font-size: 1rem;
        margin-top: 10px;
        border: none;
        cursor: pointer;
      }
      }

      .flippedLeft{
        margin-left: 10px;
        padding-left: 5px;
        display: flex;
        align-items: center !important  ;

        .descbannerImg{
          object-fit: contain;
          width: 600px;
          height: 550px;
        }
      }
    }
  }
}

@media (min-width : 320px) and (max-width : 850px){
  .flipped{
    height: auto !important;
    padding-bottom: 20px;
    .flippedContainer{
      padding-top: 20px !important;
      margin-left: 15px !important;
      margin-right: 15px !important;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      flex-direction: column-reverse!important;

      .flippedRight{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px !important;
        text-align: center;
        margin-top: 10px !important;
        .subtitle{
          width: 100%;
          font-size: 18px !important;
          font-weight: bold;
          line-height: 22px;
      }
      

      .desc{
          font-family: 'Poppins';
          width: auto !important;
          text-align: center !important;
          font-size: 18px;
      }

        .roleBtn {
        // margin-top: -150px;
        margin-top: 20px !important;
        width: 193px;
        height: 34px;
        border-radius: 30px;
      }
      }

      .flippedLeft{
        padding-top: 40px !important;
        margin-left: 10px;
        margin-right: 10px !important;
        padding-left: 5px;
        display: flex;
        align-items: center !important  ;

        .descbannerImg{
          object-fit: contain;
          width: 300px;
          height: 350px;
        }
      }
    }
  }
}
