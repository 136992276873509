.dropdown-menu{
    // margin-right: 90px;
    width: 720px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    left: 740px;
    top: 110px;
    height: 520px;
    background: rgb(22,22,21);
    background: radial-gradient(circle, rgba(53, 53, 52, 0.895) 100%, rgb(212, 212, 201) 100%);
    list-style: none;
    text-align: center;
    z-index: 13;
    font-size: 1.2rem;

    li{
        padding-top: 20px;
        border-radius: 5px;
        text-transform: uppercase;
        text-decoration: none;
        list-style: none;
        height: 50px;
        /* background: #1888ff; */
        // background: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
        padding-right: 0px;
        cursor: pointer;
        display: flex;

        // align-items: center;
        text-align: start;
        flex-direction: column;
        z-index: 13;
        font-size: 12px;
        display: inline-block;
        position: relative;
        color: #00FFB8;

        .dropdown-link {
             color: #FFFFFF;
            display: block;
            height: 100%;
            width: 100%;
            background-color: none;
            text-decoration: none;
            z-index: 13;

            /* color: #fff; */
            padding: 15px;
        }

    // 
        .dropdown-link:hover{
            color: #00FFB8;
        }
    }

    li:hover {
        background: none;
        // text-decoration-style: solid;
        // text-decoration: underline;
        list-style: none;
        // text-underline-position:above;
        height: 50px;
        list-style: none;
        color: #00FFB8;
        // text-decoration: underline;
        text-decoration-style: solid;
        // text-decoration-thickness: 3px;
        z-index: 13;
      
      }
      
      li::after{
        content: '';
  position: absolute;
  width: 65%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 15px;
  // padding-left: 5px;
  background-color: #00FFB8;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;

      }

      li:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      


}

.dropdown-menu-clicked{
    display: none;
}

// @media screen and (max-width: 960px) {
//     .fa-caret-down {
//       display: none;
//     }
//   }

  @media (min-width : 760px) and (max-width: 1500px) {
    .dropdown-menu{
      left: 540px;
      top: 110px;
    }
     .fa-caret-down {
      display: none;
    }
  }

  