.currentOpenings{
  width: 100%;
  height: 1590px;
  
  .currentOpeningsContainer{
    margin-left: 90px;
    margin-right: 90px;
    display: flex;
    flex-direction: column;

    // justify-content: center;
    // flex-direction: column;
    flex: 1;

    .top {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 0.3;
      
  
      .subtitle {
        font-size: 2.5rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 20px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }
      .searchComp{
        
        .formItems{
          padding: 20px;
          justify-content: center;
          display: flex;
          flex-direction: row;
          .inputFields{
            margin: 10px;
            padding-left: 5px;
            width: 590px;
            height: 33px;
            border: 0.5px solid lightgray;
            font-size: 0.8rem;
          }
    
          .helpSelect{
            margin: 8px;
            padding-left: 0px;
            width: 200px;
            height: 33px;
            font-size: 0.8rem;
            border-radius: 0%;
          }
  
          .roleBtn {
            margin-left: 20px;
            margin-top: 10px;
            // margin-top: -150px;
            width: 123px;
            height: 34px;
            background-color: #d22599;
            color: #ffff;
            border: none;
            border-radius: 0px;
            border: none;
            cursor: pointer;
          }
    
          
        }
      }
  
    }
    .bottom{
      display: flex;
      flex-direction: column;
      flex: 0.7;
      text-align: start;
      padding-left: 15%;
      // align-items: center;

      .subtitle {
        font-size: 1.5rem;
        font-weight: bold;
        color: #020e5a;
      }

      .boxComp{
        margin-top: 20px;
        width: 80%;
        height: 150px;
        border: 1px solid #9F9A9A;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #F4F2F2;
        display: flex;
        flex-direction: row;
        // flex: 1;
        
        .left{
          text-align: start;
          display: flex;
          flex: 0.7;
          flex-direction: column;
          padding-top: 30px;

          .title{
            padding-left: 10px;
            font-weight: bold;
          }

          .desc{
            padding-top: 10px;
            display: flex;
            flex-direction: row;
            font-size: 0.8rem;


          }
          .items{
            padding: 10px
          }
        }

        .right{
          display: flex;
          flex: 0.3;

          .applyBtn {
            letter-spacing: 2px;
            margin-left: 20px;
            margin-top: 50px;
            background-color: white;
            border: 2px solid #c13895;
            // margin-top: -150px;
            width: 123px;
            height: 34px;
            color: black;
            cursor: pointer;
          }
        }

      }


  
    }
  }



  
}

@media (min-width: 720px) and (max-width: 1400px) {
  .currentOpenings{
    width: 100%;
    height: auto !important;
    padding-bottom: 30px !important;

    .currentOpeningsContainer{
      margin-left: 20px !important;
       margin-right: 20px;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 0.3;
      
  
      .subtitle {
        font-size: 2.5rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 10px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }
      .searchComp{
        
        .formItems{
          padding: 0px;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column !important;
          .inputFields{
            // margin: 10px;
            padding-left: 5px;
            width: 250px !important;
            height: 33px;
            border: 0.5px solid lightgray;
            font-size: 1rem;
          }
    
          .helpSelect{
            // margin: 8px;
            padding-left: 0px;
            width: 250px !important;
            height: 33px;
            font-size: 1rem;
            border-radius: 0%;
          }
  
          .roleBtn {
            // margin-left: 20px;
            margin-top: 10px;
            // margin-top: -150px;
            width: 220px;
            height: 34px;
            background-color: #d22599;
            color: #ffff;
            border: none;
        font-size: 1.4rem;

            border-radius: 0px;
            border: none;
            cursor: pointer;
          }
    
          
        }
      }
  
    }

     .bottom{
      display: flex;
      flex-direction: column;
      flex: 0.7;
      text-align: start;
      padding-left: 10px !important;
      // align-items: center;

      .subtitle {
        font-size: 1.5rem;
        font-weight: bold;
        color: #020e5a;
      }

      .boxComp{
        margin-top: 20px;
        width: 80%;
        height: 180px;
        border: 1px solid #9F9A9A;
        padding-left: 50px;
        padding-right: 50px;
        background-color: #F4F2F2;
        display: flex;
        flex-direction: row;
        // flex: 1;
        
        .left{
          text-align: start;
          display: flex;
          flex: 0.7;
          flex-direction: column;
          padding-top: 30px;

          .title{
            padding-left: 0px;
            font-weight: bold;
            font-size: 1.2rem;
          }

          .desc{
            padding-top: 10px;
            display: flex;
            flex-direction: row;
            font-size: 1.1rem;


          }
          .items{
            padding: 12px
          }
        }

        .right{
          display: flex;
          flex: 0.3;
          padding-left: 20px;

          .applyBtn {
            font-size: 1.2rem;
            letter-spacing: 1px;
            margin-left: -20px;
            margin-top: 50px;
            background-color: white;
            border: 2px solid #c13895;
            // margin-top: -150px;
            width: 93px;
            height: 54px;
            color: black;
            cursor: pointer;
          }
        }

      }


  
    }


    }


  }


  
}
@media (min-width: 320px) and (max-width: 720px) {
  .currentOpenings{
    width: 100%;
    height: auto !important;
    padding-bottom: 20px !important;

    .currentOpeningsContainer{
      margin-left: 20px !important;
       margin-right: 20px;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 0.3;
      
  
      .subtitle {
        font-size: 2.5rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 10px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }
      .searchComp{
        
        .formItems{
          padding: 0px;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column !important;
          .inputFields{
            // margin: 10px;
            padding-left: 5px;
            width: 150px !important;
            height: 33px;
            border: 0.5px solid lightgray;
            font-size: 0.8rem;
          }
    
          .helpSelect{
            // margin: 8px;
            padding-left: 0px;
            width: 150px !important;
            height: 33px;
            font-size: 0.8rem;
            border-radius: 0%;
          }
  
          .roleBtn {
            // margin-left: 20px;
            margin-top: 10px;
            // margin-top: -150px;
            width: 120px;
            height: 34px;
            background-color: #d22599;
            color: #ffff;
            border: none;
            border-radius: 0px;
            border: none;
            cursor: pointer;
          }
    
          
        }
      }
  
    }

     .bottom{
      display: flex;
      flex-direction: column;
      flex: 0.7;
      text-align: start;
      padding-left: 10px !important;
      // align-items: center;

      .subtitle {
        font-size: 1.5rem;
        font-weight: bold;
        color: #020e5a;
      }

      .boxComp{
        margin-top: 20px;
        width: 90%;
        height: 170px;
        border: 1px solid #9F9A9A;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #F4F2F2;
        display: flex;
        flex-direction: row;
        // flex: 1;
        
        .left{
          text-align: start;
          display: flex;
          flex: 0.7;
          flex-direction: column;
          padding-top: 30px;

          .title{
            
            padding-left: 0px;
            font-weight: bold;
          }

          .desc{
            padding-top: 10px;
            display: flex;
            flex-direction: row;
            font-size: 0.8rem;


          }
          .items{
            padding: 12px
          }
        }

        .right{
          display: flex;
          flex: 0.3;

          .applyBtn {
            font-size: 0.7rem;
            letter-spacing: 1px;
            margin-left: -20px;
            margin-top: 50px;
            background-color: white;
            border: 2px solid #c13895;
            // margin-top: -150px;
            width: 53px;
            height: 34px;
            color: black;
            cursor: pointer;
          }
        }

      }


  
    }


    }


  }


  
}