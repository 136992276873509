.modalsidebar{
    margin-top: -85px;
    position: fixed;
   
    top: 0%;
    left: 80%;
    bottom: 0;
    background: transparent;
    // background-color: rgba(189,189,189,0.4);
}

.modalsidebarContainer{
    width: 100% !important;
    height: 100% !important;
    z-index: 0;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    scroll-behavior: smooth;
    
  
  
    
}