.testimonials{
    width: 100%;
    height: 820px;
    padding-top: 125px;
    padding-bottom: 25px;

    .testimonialContainer{
        padding-left: 150px;
        padding-right: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .title{
            color : #817F7F;
        }
        .subtitle{
            color: #020E5A;
            font-size: 2.5rem;
            font-weight: bold;
        }
        .divBelowsubtitle{
            margin-top: 30px;
            width: 76px;
            height: 2px;
            background-color: #C13895;
        }
        .descriptionDiv{
            display: flex;
            align-items: center;
            margin-right: 50px;
            justify-content: center;
            
            .desc{
                color: 'black';
                padding-top: 20px;
                font-size: 1rem;
                width: 105%;

            }


        }
        .testimonialBox{
            width: 1299px;
            height: 396px;
            border: 1px solid gray;
            display: flex;
            flex-direction: row;
            

            .boxContainer{
                display: flex;
                gap: 50px;
                padding-left: 100px;
                padding-right: 250px;
                // flex : 1;
                justify-content: space-between;

                .photoContainer{
                    // flex : 0.5;
                    align-items: center;
                    justify-content: center;
                    align-items: center;
                    

                    .reviewerImg{
                        padding-top: 10%;
                        width: 260px;
                        height: 279px;
                    }


                }
    
                .reviewContainer{
                    // flex : 0.5;
                    height: 70%;
                    padding: 0px;
                    display: flex;
                    width: auto;
                    align-items: center;
                    // padding-top: 20px;
                    align-items: start;                    
                    // justify-content: center;
                    display: flex;
                    flex-direction: column;
                    padding-right: 10px;

                    h4{
                        font-size: 1.2rem;
                    }
                    // align-content: space-evenly;

                    p{

                        text-align: start;
                        display: flex;
                        align-content: space-around;
                        font-size: 0.85rem;
                        width: 100%;
                    }
                }
            }

        }

        .connectwithusBox{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 30px;
            padding-top: 60px;
            // margin: 30px;

            .subtitle{
                color: #020E5A;
                font-size: 2.5rem;
                font-weight: bold;
                margin-right: 20px;
            }

            .roleBtn{
                width:260px ;
             height:64px;
             background-color: #D22599;
             color: #FFFF;
             border: none;
             font-size: 1.2rem;
            border-radius:70px;
            border:none;
            cursor: pointer;
            }
            
        }
        // justify-content: center;
    }
}