.ctest1 {
    width: 100%;
    height: 960px;
    padding-top: 100px;
  
    .ctestComponent1 {
      margin-left: 70px;
      margin-right: 70px;
      height: 100%;
      display: flex;
      justify-content: center;
      // flex-direction: row;
      flex: 1;
  
      .boxComp1{
          width: 1300px;
          height: 750px;
          // border: 1px solid black;
          display: flex;
          flex-direction: column;
  
          .box1{
              display: flex;
              flex-direction: row;
  
              .container1{
                  width: 650px;
                  height: 375px;
                //   padding-left: 90px;
                  margin-top: 30px;
                  align-items: flex-start;
        flex-direction: column;
        text-align: start;
  
                  .title {
                      font-size: 14px !important;
                      color: #817f7f !important;
                    }
                    .subtitle {
                      font-size: 1.5rem;
                      font-weight: bold;
                      color: #020e5a;
                    }
                    .divBelowsubtitle {
                      margin-top: 40px;
                      width: 76px;
                      height: 2px;
                      background-color: #c13895;
                    }
              
                    .descriptionDiv {
                      display: flex;
                      align-items: start;
                      flex-direction: column;
                      text-align: start;
              
                      .description {
                        color: black !important;
                        padding-top: 20px;
                        font-size: 1rem;
                        width: 75%;
                        display: flex;
                      }
                    }
              
                  
              }
              .container2{
                  width: 650px;
                  height: 375px;
                //   padding-left: 10px;
                //   background-color: #317FFF;
                  margin-top: 30px;
                  align-items: flex-start;
        flex-direction: column;
        text-align: start;
              }

              .containerImg{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
  
              .title {
                  padding-top: 10px;
                  font-size: 14px !important;
                  color: white !important;
                }
  
                .descriptionDiv {
                  display: flex;
                  align-items: start;
                  flex-direction: column;
                  text-align: start;
                  color: #FFFF !important;
          
                  p {
                    padding-top: 20px;
                    font-size: 1rem;
                    width: 55%;
                    display: flex;
                  }
                }
  
                .nameCont{
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  margin-top: 20px;
  
                  .imgCont{
                      margin-top: 20px;
  
                  }
                  .nameCont{
                      display: flex;
                      flex-direction: column;
                      padding-left: 10px;
  
                      .name{
                          color: #FFFF;
                      }
                      .designation{
                          color: #FFFF;
                      }
                  }
                }
  
  
          }
          .box2{
              display: flex;
              flex-direction: row;
  
              .container1{
                //   padding-top: 30px;
                  width: 650px;
                  height: 375px;
                //   padding-left: 30px;
                //   background-color: #00B47E;
                  align-items: flex-start;
        flex-direction: column;
        text-align: start;
        // .title {
        //   padding-top: 10px;
        //   font-size: 14px !important;
        //   color: white !important;
        // }
  
        // .descriptionDiv {
        //   display: flex;
        //   align-items: start;
        //   flex-direction: column;
        //   text-align: start;
        //   color: #FFFF !important;
  
        //   p {
        //     padding-top: 20px;
        //     font-size: 1rem;
        //     width: 55%;
        //     display: flex;
        //   }
        // }
  
        // .nameCont{
        //   display: flex;
        //   flex-direction: row;
        //   align-items: flex-start;
        //   margin-top: 20px;
  
        //   .imgCont{
        //       margin-top: 20px;
  
        //   }
        //   .nameCont{
        //       display: flex;
        //       flex-direction: column;
        //       padding-left: 10px;
  
        //       .name{
        //           color: #FFFF;
        //       }
        //       .designation{
        //           color: #FFFF;
        //       }
        //   }
        // }

        .containerImg{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }


  
  
  
              }
              .container2{
                //   padding-top: 30px;
                  width: 650px;
                  height: 375px;
                //   padding-left: 30px;
                //   background-color: #000C52;
                  align-items: flex-start;
                  flex-direction: column;
                  text-align: start;

                  .containerImg{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
  
  
                //   .title {
                //       padding-top: 10px;
                //       font-size: 14px !important;
                //       color: white !important;
                //     }
      
                //     .descriptionDiv {
                //       display: flex;
                //       align-items: start;
                //       flex-direction: column;
                //       text-align: start;
                //       color: #FFFF !important;
              
                //       p {
                //         padding-top: 20px;
                //         font-size: 1rem;
                //         width: 55%;
                //         display: flex;
                //       }
                //     }
  
                //     .nameCont{
                //       display: flex;
                //       flex-direction: row;
                //       align-items: flex-start;
                //       margin-top: 20px;
      
                //       .imgCont{
                //           margin-top: 20px;
      
                //       }
                //       .nameCont{
                //           display: flex;
                //           flex-direction: column;
                //           padding-left: 10px;
      
                //           .name{
                //               color: #FFFF;
                //           }
                //           .designation{
                //               color: #FFFF;
                //           }
                //       }
                //     }
      
              }
          }
  
      }
    }
  }
  