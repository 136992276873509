// .menu {
// 	position: relative;
// }

// .menu ul {
//     margin: 0;
//     padding: 0;
//     list-style: none;
      
//   }
  
//   .open-panel {
//     border: none;
//     background-color:#fff;
//     padding: 0;
//   }
  
//   .hamburger {
//       background: transparent;
//       position: relative;
//       display: block;
//       text-align: center;
//       padding: 13px 0;
//       width: 50px;
//     height: 73px;
//       right: 0;
//     top: 0;
//       z-index: 1000;
//     cursor: pointer;
//   }
  
//   .hamburger:before {
//       content:"\2630"; /* hamburger icon */
//       display: block;
//     color: #000;
//     line-height: 32px;
//     font-size: 16px;
//   }
  
.sidebar {
    // background: #020E5A;
    background: transparent;
    color: white;
    height: 100vh;
    overflow-y: auto;
    position: relative;
  }
  
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }
  .logo {
    font-size: 18px;
    line-height: 0;
  }
  .bars {
    width: 30px;
  }
  .hide {
    display: none;
  }
  
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
  }
  .search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
  
    color: white;
  }
  
  .routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .link {
    display: flex;
    color: white;
    text-transform: none;
    text-decoration: none;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link:hover {
    border-right: 4px solid white;
    // background: rgb(45, 51, 89);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .active {
    border-right: 4px solid white;
    background: transparent
  }
  .link_text {
    white-space: nowrap;
    font-size: 15px;
  }
  
  .menu {
    display: flex;
    color: white;
  
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }
  .menu_item {
    display: flex;
    gap: 10px;
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
  }


  @media (min-width : 320px) and (max-width: 760px) {
    .sidebar{
        top: 20px;
        height: auto !important;
        padding-bottom: 10px;
    }

    .routes{
        padding-top: 0px !important;
    }

    .link{
      text-decoration: none;
      text-transform: none;
    }

    .link_text{
        font-size: 0.8rem;
        text-decoration: none;
        text-transform: none;
    }

    .active{
        background: transparent;
    }

    .link:hover{
      background: transparent;
    }
  }