.desccommon {
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  // display: flex;
  // flex-direction: row;
  background-color: #ffffff;
  // position: relative;
  // flex: 1;

  .desccommonContainer {
    margin-left: 150px;
    margin-right: 150px;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    // flex: 1;
    padding-top: 100px;

    .descLeft {
      display: flex;
      // flex: 0.5;
      align-items: flex-start;
      flex-direction: column;
      text-align: start;


      .title {
        font-size: 24px !important;
        color: #817f7f !important;
      }
      .subtitle11 {
        font-size: 2.9rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 30px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }

      .descriptionDiv {
        display: flex;
        align-items: start;
        flex-direction: column;
        text-align: start;

        desc {
          padding-top: 20px;
          font-size: 1rem;
          width: 75%;
          display: flex;
        }
      }

      .roleBtn {
        // margin-top: -150px;
        width: 353px;
        height: 54px;
        background-color: #d22599;
        color: #ffff;
        border: none;
        border-radius: 70px;
        font-size: 1.2rem;
        margin-top: 30px;
        border: none;
        cursor: pointer;
      }
    }

    .descRight {
      margin-left: 150px;
      display: flex;
      padding-left: 115px;
      // flex: 0.5;
      // z-index: 1;
      
      

      .descbannerImg {
        width: 494px;
        height: 555px;
        z-index: 1;
      }
    }
  }
}

@media (min-width : 850px) and (max-width : 1600px){
  .desccommon{
    padding-bottom: 20px;
    .desccommonContainer{
      padding-top: 0px !important;
      margin-left: 30px;
      margin-right: 30px;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      flex-direction:column-reverse !important;

      .descLeft{
        .divBelowsubtitle{
          margin-top: 15px;

          .descriptionDiv{

            .desc {
              padding-top: 20px;
              font-size: 2.5rem !important;
              width: 75%;
              display: flex;
            }
          }
        }

        

        
      }

      .descRight{
        margin-left: 10px;
        padding-left: 20px;
        align-items: center;

        .descbannerImg{
          width: 700px;
          height: 650px;
          object-fit: contain;
        }
      }
    }
  }
}

@media (min-width : 320px) and (max-width : 850px){
  .desccommon{
    padding-bottom: 20px;
    .desccommonContainer{
      padding-top: 0px !important;
      gap: 5px;
      margin-left: 30px;
      margin-right: 30px;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      flex-direction: column !important;

      .descLeft{
          display: flex !important;
          align-items: center !important;
          flex-direction: column;
          justify-content: center;
            // flex-direction: column !important;
        .subtitle11{
          // width: 100%;
          font-size: 18px !important;
          font-weight: bold;
          // line-height: 22px;
          // margin-left: 50%;
      }
      .divBelowsubtitle {
        margin-top: 30px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }
      
    .descriptionDiv{
      width: 100%;
      margin-right: 0px;

      .desc{
          display: flex;
          font-size: 18px !important;
          width: 100%;
          text-align: center;
          line-height: 23px;
      }
  }

        .roleBtn {
        // margin-top: -150px;
        width: 193px !important;
        height: 34px !important;
        background-color: #d22599;
        color: #ffff;
        border: none;
        border-radius: 30px;
        font-size: 1rem;
        margin-top: 10px;
        border: none;
        cursor: pointer;
      }
      }

      .descRight{
        margin-left: 10px;
        padding-left: 5px;
        display: flex;
        align-items: center !important  ;

        .descbannerImg{
          object-fit: contain;
          width: 300px;
          height: 350px;
        }
      }
    }
  }
}
