.partners{
    width: 100%;
    overflow-x: hidden;
    height: auto !important;
    padding-bottom: 10px;
    // display: flex;
    // flex-direction: row;
    background-color: #DFDFE0;
    // position: relative;
    // flex: 1;

    .partnersContainer{
        padding-left: 150px;
        padding-right: 150px ;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: auto !important;
        // flex: 1;

        .partnersLeft{
            display: flex;
            align-items: center;
            margin-top: 80px;

            
            // flex : 0.4;
            flex-direction: column;
            // z-index: 1;
            // position: absolute;
            // margin-left: -650px;
            // width: 670px;

            .title{
                color : #817F7F;
            }
            .subtitle{
                color: #020E5A;
                font-size: 2.9rem;
                font-weight: bold;
            }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }
            .descriptionDiv1{
                display: flex;
                align-items: start;
                line-height: 23px;
                margin-right: 50px;
                // justify-content: flex-start;
                
                .desc{
                    color: 'black';
                    text-align: start;
                    padding-top: 20px;
                    font-size: 1.2rem;
                    width: 100%;
                    display: flex;

                }

            }
        }

        .partnersRight{
            margin-top: 150px;
            display: flex;
            // flex: 0.6;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-left: auto;
            // position: absolute;
            padding-left: 200px;

           .arrow1{
            width: 55px;
                height: 25px;
                margin-top: 220px;
                margin-right: 20px;
                position: absolute;
                cursor: pointer;
           }

            .containerOneMobile{
                display: flex;
                flex-direction: column;
                align-items: center !important;
                width: 390px;
                height: 400px !important;
                padding: 25px;
                gap: 0px;
                .box1mobile{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    justify-content: center;
                    width: 300px !important;
                    height: 250px !important; 

                    .boxImg{
                        width: 90%;
                        height: 100% !important;
                        object-fit: cover;
                        display: flex;
                        // background-image: linear-gradient(rgba(0,0,0,0.7),#477DFF);
    
                      
                    }
                }

                .box2mobile{
                    display: none !important;
                }
            }

            .arrow{
                width: 55px;
                height: 25px;
                margin-top: 120px !important;
                margin-right: 1220px;
                position: absolute;
                // margin-right: '1220px';
                cursor: pointer;
                
            }
    
            .containerOne{
                display: flex;
                flex-direction: column;
                width: 390px;
                height: 500px;
                padding: 25px;
                gap: 0px;
    
                .box1{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    width: 390px;
                    height: 300px;
                    // position: relative;

                    // background-color: #477DFF;
    
                    .boxImg{
                        width: 90%;
                        height: 320px;
                        display: flex;
                        background-image: linear-gradient(rgba(0,0,0,0.7),#477DFF);
    
                      
                    }
                }
    
                .box2{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    height: 150px;
                    // height: 150px;
                    background-color: white;
                }
    
    
        }
    }
    }

}

// @media (min-width : 710px) and (max-width: 1400px) {
//     .partnersContainer{
//         margin-left: 25px !important;
//         margin-right: 25px !important;
//         gap: 50px;

//         .partnersLeft{
//             display: flex;
//         flex-direction: column !important;
//         // gap: 50px;
//         padding-left: 10px !important;
//         padding-right: 10px !important;
//             width: 100% !important;
//             .descriptionDiv{
//                 line-height: 23px;
                
//                 .desc{
//                     width: 100%;
//                 }
//             }
//         }

//         .partnersRight{
//             padding-top: 50px;
//             // padding-left: auto;
//             // padding-left: 450px;

//             .arrow{
//                 width: 55px;
//                 height: 25px;
//                 margin-left: 155px;
//                 padding-top: 360px;
//                 position: absolute;
//                 cursor: pointer;
                
//             }

//             .containerOne{
//                 width: 290px;
//                 height: 300px;
//                 padding: 15px;

//                 .box1{
//                     width: 250px;

//                     .boxImg{
//                         width: 60%;
//                         height: 220px;
//                     }
//                 }

//                 .box2{
//                     width: 60%;
//                     height: 100px;
//                 }
//             }
//         }
//     }
    
// }

@media (min-width : 850px) and (max-width : 1400px)  {
    .partners{
        overflow-x: hidden;
        padding-top: 20px;
        height: auto !important;
   
        padding-bottom: 10px;
        // align-items: center !important;
        // justify-content: center !important;
        // position: relative;
    }
    
    .partnersContainer{
        display: flex;
        flex-direction: column !important;
        gap: 20px;
        padding-left: 20px !important;
        padding-right: 20px !important;
       align-self: center;
       justify-content: center;
        

        .partnersLeft{
            width: 100% !important;
           margin-left: 10px !important;
            margin-top: 30px !important;
            

            .subtitle{

                font-size: 2rem !important;
            }

            .descriptionDiv1{
                display: flex;
                align-items: start;
                // line-height: 23px;
                
                
                .desc{
                    width: 100%;
                    font-size: 0.8rem;
                }
            }
        }

        .partnersRight{
            margin-top: 50px !important;
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin-right: 10px !important;
            margin-left: 10px !important;
            // padding-left: auto;
            // padding-left: 450px;

            .arrow{
                padding-top: 160px !important;
                padding-left: 90%;
            }

            .containerOne{
                width: auto;

                .box1{  
                    width: 283px !important;
                    height: 120px !important;

                    .boxImg{
                        object-fit: contain;
                        // width: 60%;
                    }
                }

                .box2{
                    width: 258px !important;
                    height: 120px;
                }
            }
        }
    }
    
}

@media (min-width : 320px) and (max-width : 850px)  {
    .partners{
        overflow-x: hidden;
        padding-top: 20px;
        height: auto !important;
   
        padding-bottom: 10px;
        // align-items: center !important;
        // justify-content: center !important;
        // position: relative;
    }
    
    .partnersContainer{
        display: flex;
        flex-direction: column !important;
        // gap: 50px;
        padding-left: 15px !important;
        padding-right: 15px !important;
        align-items: center;
    //    align-self: center;
       justify-content: center;
        

        .partnersLeft{
            display: flex;
            align-items: center !important;
            justify-content: center;
            width: 100% !important;
            padding-left: 25px !important;
            margin-right: 20px !important;
            margin-left: 20px !important;
            margin-top: 30px !important;
            

            .subtitle{

                font-size: 18px !important;
            }

            .descriptionDiv1{
                display: flex;
                align-items: start;
                // line-height: 23px;
                
                
                .desc{
                font-family: 'Poppins';
                font-size: 18px !important;
                display: flex;
                line-height: 22px;
                width: auto !important;
                text-align: center !important;
                }
            }
        }

        .partnersRight{
            margin-top: 50px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
            margin-right: 10px !important;
            margin-left: 10px !important;
            // padding-left: auto;
            // padding-left: 450px;

            .arrow{
                display: none !important;
                padding-top: 560px;
                padding-left: 0% !important;
            }

            .containerOne{
                width: auto;

                .box1{  
                    width: 170px !important;
                    height: 120px !important;

                    .boxImg{
                        object-fit: contain;
                        // width: 60%;
                    }
                }

                .box2{
                    width: 153px !important;
                    height: 120px;
                }
            }
        }
    }
    
}


// @media  (max-width : 700px)  {
//     .partners{
//         padding-top: 20px;
//         overflow-x: hidden;
   
//         padding-bottom: 10px;
//         // align-items: center !important;
//         // justify-content: center !important;
//         // position: relative;
//     }
    
//     .partnersContainer{
//         display: flex;
//         flex-direction: column !important;
//         // gap: 50px;
//         padding-left: 10px !important;
//         padding-right: 10px !important;
//        align-self: center;
//        justify-content: center;
        

//         .partnersLeft{
//             width: 100% !important;
//            margin-left: 10px !important;
//             margin-top: 30px !important;
            

//             .subtitle{

//                 font-size: 2rem !important;
//             }

//             .descriptionDiv1{
//                 display: flex;
//                 align-items: start;
//                 // line-height: 23px;
                
                
//                 .desc{
//                     width: 100%;
//                     font-size: 0.8rem;
//                 }
//             }
//         }

//         .partnersRight{
//             margin-top: 20px;
//             margin-left: 10px !important;
//             margin-right: 10px !important;
//             // padding-left: auto;
//             // padding-left: 450px;

//             .arrow{
//                 padding-top: 360px;
//                 padding-left: 90%;
//             }

//             .containerOne{
                
//                 width: 190px;
//                 height: 400px;
//                 padding: 15px;

//                 .box1{
//                     width: 220px;

//                     .boxImg{
//                         width: 60%;
//                         height: 220px;
//                     }
//                 }

//                 .box2{
//                     width: 220px;
//                     height: 100px;
//                 }
//             }
//         }
//     }
    
// }

