.values{
    width: 100%;
    height: 851px;
    display: flex;
    flex-direction: column;
    // z-index: 0;
    // position: relative;


    .backgroundImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        // background-image: linear-gradient(rgba(0,0,0,0.7),#002939),url(../../../assets/values/values.png);
        // position: relative;
    }

    

    .servicesTop{
        margin-left: 150px;
        margin-right: 150px;
        display: flex;
        align-items: column;
        justify-content: flex-start;
        position: absolute;
        flex: 0.7;
        flex-direction: column;
        z-index: 1;

        .valuesDesc{
            text-align: start;
            display: flex;
            flex-direction: column;
            align-items: start;
            padding-top: 30px;
        //    line-height: 1.2;

            .title{
                // font-size: 24px !important;
                color : #00FFB8 !important;
                
            }
            .subtitle{
                   padding-top: 10px;
                    font-size: 2.5rem;
                    font-weight: bold;
                    color : #FFFFFF
                }
            .divBelowsubtitle{
                margin-top: 30px;
                width: 76px;
                height: 2px;
                background-color: #C13895;
            }

            .descriptionDiv{
                display: flex;
                align-items: start;
    
                p{
                    width: 650px;
                    display: flex;
                    align-self: flex-start;
                    line-height: 1.5;
                    color :#FFFFFF;
                    font-size : 1.3rem;
                    text-align: start;
                }
            }

            .arrows{
                width: 45px;
                padding-top: 30px;
                
            }


        }
    }

    .servicesBottom{
        
        display: flex;
        flex: 0.3;
        width: 100%;
        flex-direction: row;
        height: 200px;
        position: absolute;
        padding-top: 500px;


        .valuesContainer{

            padding-left: 150px;
            padding-right: 120px;
            display: flex;
            align-self: flex-start;
            flex-direction: column;
            text-align: start;
            line-height: 1.5;

            .no{
                margin-top: 50px;
                font-size: 1rem;
                color: #FFFFFF;
            }
            .desc{
                padding-top: 5px;
                flex-direction: row;
                display: flex;
                color: #FFFFFF;
                font-size: 1rem;
                font-weight: bold;
            }
            
        }

        .lineContainer{
            width: 0;
            height: 109px;
            display: flex;
            flex-direction: row;
            padding-top: 50px;
        }
    }

}

@media (min-width: 850px) and (max-width: 1600px) {
    .values{
        width: 100%;
        height: 1330px !important;

        .backgroundImg{
            object-fit: cover !important;
        }

        .servicesTop{
            margin-left: 20px !important;
            margin-right: 20px !important;
            display: flex;
            align-items: column;
            justify-content: flex-start;
            position: absolute;
            flex: 0.7;
            flex-direction: column;
            z-index: 1;
    
            .valuesDesc{
                text-align: start;
                display: flex;
                flex-direction: column;
                align-items: start;
                padding-top: 30px;
                width: auto !important ; 
            //    line-height: 1.2;
    
                .title{
                    // font-size: 24px !important;
                    color : #00FFB8 !important;
                    
                }
                .subtitle{
                       padding-top: 10px;
                        font-size: 2.5rem;
                        font-weight: bold;
                        color : #FFFFFF
                    }
                .divBelowsubtitle{
                    margin-top: 30px;
                    width: 76px;
                    height: 2px;
                    background-color: #C13895;
                }
    
                .descriptionDiv{
                    display: flex;
                    align-items: start;
        
                    p{
                        width: auto !important;
                        display: flex;
                        align-self: flex-start;
                        line-height: 1.5;
                        color :#FFFFFF;
                        font-size : 1.3rem;
                        text-align: start;
                    }
                }
    
                .arrows{
                    // display: none !important;
                    width: 45px;
                    padding-top: 20px;
                    
                }
    
    
            }
        }
    
        .servicesBottom{
            
            display: flex;
            flex: 0.3;
            width: 100%;
            flex-direction: column !important;
            height: 200px;
            position: absolute;
            padding-top: 450px;
    
    
            .valuesContainer{
                padding-top: 20px !important;
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                align-self: flex-start;
                flex-direction: column;
                text-align: start;
                line-height: 1.5;
    
                .no{
                    // margin-top: 50px;
                    font-size: 2rem;
                    color: #FFFFFF;
                }
                .desc{
                    padding-top: 5px;
                    flex-direction: row;
                    display: flex;
                    color: #FFFFFF;
                    font-size: 2rem;
                    font-weight: bold;
                }
                
            }
    
            .lineContainer{
                display: none !important;
                width: 0;
                height: 109px;
                display: flex;
                flex-direction: row;
            }
        }


    }

}

@media (min-width: 320px) and (max-width: 850px) {
    .values{
        width: 100%;
        height: 1150px !important;
        align-items: center;

        .backgroundImg{
            object-fit: cover !important;
        }

        .servicesTop{
            margin-left: 20px !important;
            margin-right: 20px !important;
            display: flex;
            align-items: column;
            justify-content: flex-start;
            position: absolute;
            flex: 0.5;
            flex-direction: column;
            z-index: 1;
            margin-top: 80%;
    
            .valuesDesc{
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 30px;
                width: 350px !important;
            //    line-height: 1.2;
    
                .title{
                    // font-size: 24px !important;
                    color : #00FFB8 !important;
                    
                }
                .subtitle{
                    width: 100%;
                    font-size: 18px !important;
                    font-weight: bold;
                    line-height: 22px;
                    }
                .divBelowsubtitle{
                    margin-top: 30px;
                    width: 76px;
                    height: 2px;
                    background-color: #C13895;
                }
    
                .descriptionDiv{
                    display: flex;
                    align-items: start;
        
                    p{
                        width: 350px !important;
                        display: flex;
                        align-self: flex-start;
                        line-height: 1.5;
                        color :#FFFFFF;
                        font-size : 1rem;
                        text-align: center;
                    }
                }
    
                .arrows{
                    display: none !important;
                    width: 45px;
                    padding-top: 50px;
                    
                }
    
    
            }
        }
    
        .servicesBottom{
            
            display: flex;
            flex: 0.5;
            width: 100%;
            flex-direction: column !important;
            height: 100px !important;
            position: absolute;
            padding-top: 600px;
    
    
            .valuesContainer{
                padding-top: 50px !important;
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                align-self: flex-start;
                flex-direction: row;
                text-align: start;
                max-height: 20px !important;

    
                .no{
                    margin-top:40px;
                    font-size: 1rem;
                    color: #FFFFFF;
                }
                .desc{
                    padding-top: 38px;
                    flex-direction: row;
                    display: flex;
                    color: #FFFFFF;
                    font-size: 1rem;
                    font-weight: bold;
                    padding-left: 20px;
                }
                
            }
    
            .lineContainer{
                display: none !important;
                width: 0;
                height: 0px !important;
               
            }
        }


    }

}