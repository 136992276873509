  
  .swiper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -99;
  }

  .swiper-button-next,
 .swiper-button-prev{
    visibility: hidden;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    .main{
      display: flex;
      flex-direction: row ;
      .title{
          display: flex;
          flex-direction: column;
          padding-left: 110px;
          font-size: 55px;
          line-height: 0.01px;
          gap: 2px !important;
          color: azure;
          top: 390px;
          left: 50px;
          
         position: absolute;
      
      z-index: 1;
     }
     .desc{
      display: flex;
          flex-direction: column;
          padding-left: 110px;
      font-size: 28px;
      line-height: 0.1px;
          color: azure;
          top: 720px;
          left: 50px;
          
         position: absolute;
     }
      img{
          z-index: -99;
          position: relative;
      }
     }
  }
  
  .swiper-slide img {
    // display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 1200px) and (max-width : 1800px) {
    .swiper-slide{
        .main{
            display: flex;
    flex-direction: column;
            .title{
                padding-left: 20px !important;
                font-size: 2.9rem;
                color: azure;
                text-align: start;
                top: 290px;
                left: 150px;
                
               position: absolute;
            
            z-index: 1;
           }
           .desc{
            padding-left: 20px !important;
            font-size: 1.7rem;
                color: azure;
                top: 540px;
                text-align: start;
                left: 150px;
                
               position: absolute;
           }
            img{
                width: auto !important;
                height: 950px !important;
                z-index: -99;
                position: relative;
                object-fit: cover;
            }
        }
    }
}


  @media (min-width: 760px) and (max-width : 1200px) {
    .swiper-slide{
        .main{
            display: flex;
    flex-direction: column;
            .title{
                padding-left: 20px !important;
                font-size: 1.5rem;
                color: azure;
                text-align: start;
                top: 290px;
                left: 20px;
                
               position: absolute;
            
            z-index: 1;
           }
           .desc{
            padding-left: 20px !important;
            font-size: 1.2rem;
                color: azure;
                top: 490px;
                text-align: start;
                left: 20px;
                
               position: absolute;
           }
            img{
                width: 100% !important;
                object-fit: cover;
                height: 650px !important;
                z-index: -99;
                position: relative;
            }
        }
    }
}


  @media (min-width: 320px) and (max-width : 760px) {
    .swiper-slide{
        .main{
            display: flex;
    flex-direction: column;
            .title{
                font-size: 0.6rem;
                padding-left: 20px !important;
                color: azure;
                text-align: start;
                top: 160px;
                left: 20px;
                
               position: absolute;
            
            z-index: 1;
           }
           .desc{
            font-size: 0.5rem;
            padding-left: 20px !important;
                color: azure;
                top: 240px;
                left: 20px;
                width: 150px !important;
                text-align: start;
                line-height: 2;
                
               position: absolute;
           }
            img{
                width: 100% !important;
                height: 420px !important;
                object-fit: cover;
                z-index: -99;
                position: relative;
            }
        }
    }
}
  