.services{
    overflow-x: hidden;
    width: 100%;
    overflow-x: hidden;
    height: auto;
    padding-bottom: 60px;
    padding-top: 60px;
 
    background-color: #040E56;
    // position: relative;
    // flex: 1;


    
    .servicesContainer{
        padding-left: 150px;
        padding-right: 150px;
        height: 100%;
        display: flex;
        flex-direction: row;
        // gap: 20px;
        
        
        .servicesLeft{
            // position: relative;
            //     .servicesbannerImg{
            //        width: 694px;
            //        height: 955px;
            //        top: 0;
            //        object-position: 0 , 2055 ;
            //        position: absolute;
            //        z-index: 0;
            //    }
                display: flex;
                align-items: flex-start;
                margin-top: 150px;
                // flex : 0.5;
                flex-direction: column;
                z-index: 1;
                // position: absolute;
                // left: 100px;
                width: 100%;

                .title{
                    color : #FFFF;
                }
                .subtitle{
                    color: #FFFF;
                    font-size: 2.9rem;
                    line-height: 52px;
                    font-weight: bold;
                }
                .divBelowsubtitle{
                    width: 76px;
                    margin-top: 4px !important;
                    height: 2px;
                    background-color: #C13895;
                }
                .descriptionDiv1{
                    display: flex;
                    align-items: start;
                    // justify-content: flex-start;
                    
                    .desc{
                        color: #FFFF;
                        text-align: start;
                        padding-top: 20px;
                        line-height: 33px;
                        font-size: 1.2rem;
                        width: auto !important;
                        display: flex;
    
                    }
                }
            }
            .servicesRight{
                display: flex;
                // flex: 0.5;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 120%;
                // padding-left: 40px;
                gap: 18px;
                // padding-left: 30px;
        
                .containerOne{
                    display: flex;
                    flex-direction: column;
                    width: 320px;
                    height: 800px;
                    gap: 20px;
        
                    .box1{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 390px;
                        height: 388px; 
                        background-color: #477DFF;
                        
                        .logo{
                            padding-top: 50px;
                            padding-bottom: 20px;
                            padding-left: 60px;
                            width: 70px;
                            height: 70px;
                            
                        }
        
                        .title{
                            text-transform: uppercase;
                            color: #FFFFFF;
                            font-size: 1.7rem;
                            letter-spacing: 3px;
                             padding-left: 60px;
                            padding-right: 60px;
        
                        }
        
                        .desc{
                            text-align: start;
                            color: #FFFFFF;
                            font-size: 1rem;
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
        
                    .box2{
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        width: 390px;
                        height: 388px;
                        background-color: #56FFBD;
        
                        .logo{
                            padding-top: 50px;
                            padding-bottom: 20px;
                            padding-left: 60px;
                            width: 70px;
                            height: 70px;
                            color: #FFFFFF;
                        }
        
                        .title{
                            text-transform: uppercase;
                            color: #020E5A;
                            font-size: 1.7rem;
                            letter-spacing: 3px;
                             padding-left: 60px;
                            padding-right: 60px;
                        }
        
                        .desc{
                            text-align: start;
                            color: #020E5A;
                            font-size: 1rem;
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }
        
                .containerTwo{
                    display: flex;
                    flex-direction: column;
                    width: 320px;
                    height: 800px;
                    padding-top: 100px;
                    padding-left: 90px;
                    gap: 18px;
        
        
                    .box3{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                       width: 390px;
                        height: 388px;
                        background-color: #56FFBD;
        
                        .logo{
                            padding-top: 50px;
                            padding-bottom: 20px;
                            padding-left: 60px;
                            width: 70px;
                            height: 70px;
                        }
        
                        .title{
                            text-transform: uppercase;
                            color: #020E5A;
                            font-size: 1.7rem;
                            letter-spacing: 3px;
                             padding-left: 60px;
                            padding-right: 60px;
                        }
        
                        .desc{
                            text-align: start;
                            color: #020E5A;
                            font-size: 1rem;
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
        
                    .box4{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        width: 390px;
                        height: 388px;
                        margin-top: 20px;
                
                        background-color: #56FFBD;
        
                        .logo{
                            padding-top: 50px;
                            padding-bottom: 20px;
                            
                            padding-left: 60px;
                            width: 70px;
                            height: 70px;
                        }
        
                        .title{
                            text-transform: uppercase;
                            color: #020E5A;
                            font-size: 1.7rem;
                            letter-spacing: 3px;
                             padding-left: 60px;
                            padding-right: 60px;
                        }
        
                        .desc{
                            text-align: start;
                            color: #020E5A;
                            font-size: 1rem;
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }
                
        
            }

   }

}


@media (min-width : 600px) and (max-width: 1800px) {
   
    .services .servicesContainer{
        
        overflow-x: hidden;
        margin-left: 50px !important;
        // margin-right: 50px !important;
        
        .servicesLeft{
            .descriptionDiv{

                .desc{
                    width: 76%;
                    line-height: 23px;
                }
            }
        }

        .servicesRight{
            padding-right: 120px;
            .containerOne{
                width: 260px;

                .box1{
                    width: 330px;
                    .desc{
                        font-size: 0.8rem;
                    }
                }

                .box2{
                    width: 330px;
                    .desc{
                        font-size: 0.8rem;
                    }
                }

                
            }
            
            .containerTwo{
                width: 220px;
                .box3{
                    width: 330px;
                    .desc{
                        font-size: 0.8rem;
                    }
                }
    
                .box4{
                    width: 330px;
                    .desc{
                        font-size: 0.8rem;
                    }
                }
            }
        }

        
    }
    
}

@media (min-width : 850px) and (max-width: 1400px) {
   
    .services .servicesContainer{
        overflow-x: hidden;
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        padding-left: 20px;
        // padding-right: 20px;
        // margin-left: 10px;
        // margin-right: 10px;
       
        align-items: center;
        justify-content: center;
        
        .servicesLeft{
            align-items: center;
            justify-content: center;
            .descriptionDiv1{
                width: 100%;
                margin-right: 0px;

                .desc{
                    display: flex;
                    font-size: 18px !important;
                    width: 100%;
                    text-align: justify;
                    line-height: 23px;
                }
            }
        }

        .servicesRight{
            display: flex;
          
            align-items: center;
            // padding-right: 120px;
            padding-left: 5px;
            padding-right: 5px;
            
            .containerOne{
                width: 260px;

                .box1{
                    width: 330px;
                    .desc{
                        font-size: 0.6rem;
                    }
                }

                .box2{
                    width: 330px;
                    .desc{
                        font-size: 0.6rem;
                    }
                }

                
            }
            
            .containerTwo{
                width: 220px;
                .box3{
                    width: 330px;
                    .desc{
                        font-size: 0.6rem;
                    }
                }
    
                .box4{
                    width: 330px;
                    .desc{
                        font-size: 0.6rem;
                    }
                }
            }
        }

        
    }
    
}

@media  (max-width: 850px) {
   
    .services .servicesContainer{
        overflow-x: hidden;
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        padding-top: 20px;
        padding-right: 15px !important;
        padding-left: 15px !important;
        align-items: center;
        height: auto;
        margin-bottom: 20px;
        
        .servicesLeft{
            align-items: center !important;
            justify-content: center;
            margin-top: 10px;
            padding-left: 5px;

            .subtitle{
                font-size: 18px !important;
            }

            .divBelowsubtitle{
                margin-left: 10% !important;
            }
            
            .descriptionDiv1{
                margin-right: 0px;
                
                .desc{
                    font-family: 'Poppins';
                    font-size: 18px !important;
                    display: flex;
                    line-height: 22px;
                    width: auto !important;
                    text-align: center !important;
                }
            }
        }

        .servicesRight{
            align-items: center !important;
            justify-content: center !important;
            gap:18px;
            display: flex;
            // padding-left: 10px !important;
            // padding-right: 120px;

            .mobileContainers{
                display: flex;
                flex-direction: column !important;
                padding-left: 10px !important;
                padding-right: 10px !important;
                height: auto !important;
                padding-bottom: 20px !important;

                .containerOne{
                    padding-right: 5px !important;
                    padding-left: 5px !important;
                    align-items: center;
                    justify-content: center;
                    width: 270px;
                    height: 300px;
    
                    .box1{
                        width: 252px;
                        padding: 10px 10px;
                        height: 240px;
    
                        .logo{
                            padding-top: 12px;
                            padding-bottom: 2px;
                            padding-left: 10px;
                            width: 48px;
                            height: 48px;
                        }
    
                        .title{
                            font-size: 1rem;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .desc{
                            font-size: 0.9rem;
                            line-height: 16px;
                            padding-left: 15px;
                            padding-right: 15px
                        }
                    }
    
                    .box2{
                        padding: 10px 10px;
                        padding-top: 20px;
                        width: 252px;
                        height: 240px;
                        .logo{
                            padding-top: 12px;
                            padding-bottom: 2px;
                            padding-left: 10px;
                            width: 48px;
                            height: 48px;
                        }
    
                        .title{
                            font-size: 1rem;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .desc{
                            font-size: 0.9rem;
                            line-height: 16px;
                            padding-left: 15px;
                            padding-right: 15px
                        }
                    }
                }
                
                .containerTwo{
                    padding-right: 5px !important;
                    padding-left: 5px !important;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0px !important;
                    width: 270px !important;
                    height: 300px !important;

                    .box3{
                        width: 252px;
                        padding: 10px 10px;
                        height: 240px;
    
                        .logo{
                            padding-top: 12px;
                            padding-bottom: 2px;
                            padding-left: 10px;
                            width: 48px;
                            height: 48px;
                        }
    
                        .title{
                            font-size: 1rem;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .desc{
                            font-size: 0.9rem;
                            line-height: 16px;
                            padding-left: 15px;
                            padding-right: 15px
                        }
                    }
    
                    .box4{
                        padding: 10px 10px;
                        padding-top: 20px;
                        width: 252px;
                        height: 240px;
                        .logo{
                            padding-top: 12px;
                            padding-bottom: 2px;
                            padding-left: 10px;
                            width: 48px;
                            height: 48px;
                        }
    
                        .title{
                            font-size: 1rem;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .desc{
                            font-size: 0.9rem;
                            line-height: 16px;
                            padding-left: 15px;
                            padding-right: 15px
                        }
                    }
    
                }
            }



            
            .containerOne{
                padding-right: 5px !important;
                padding-left: 5px !important;
                align-items: center;
                justify-content: center;
                width: 160px;
                height: 600px;

                .box1{
                    width: 160px;
                    padding: 10px 10px;
                    height: 240px;

                    .logo{
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 10px;
                        width: 40px;
                        height: 40px;
                    }

                    .title{
                        font-size: 1rem;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    .desc{
                        font-size: 0.6rem;
                        line-height: 12px;
                        padding-left: 15px;
                        padding-right: 15px
                    }
                }

                .box2{
                    padding: 10px 10px;
                    padding-top: 20px;
                    width: 160px;
                    height: 240px;
                    .logo{
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 10px;
                        width: 40px;
                        height: 40px;
                    }

                    .title{
                        font-size: 1rem;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    .desc{
                        font-size: 0.6rem;
                        line-height: 12px;
                        padding-left: 15px;
                        padding-right: 15px
                    }
                }

                
            }
            
            .containerTwo{
                width: 160px;
                height: 600px;
                align-items: center;
                justify-content: center;
                padding-right: 5px !important;
                padding-left: 5px !important;
                gap: 5px;
                margin-top: 20px;
                .box3{
                    width: 160px;
                    padding-right: 10px;
                    // padding: 10px 10px;
                    
                    height: 240px;
                    .logo{
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 10px;
                        width: 40px;
                        height: 40px;
                    }

                    .title{
                        font-size: 1rem;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    .desc{
                        font-size: 0.6rem;
                        line-height: 12px;
                        padding-left: 15px;
                        padding-right: 15px
                    }
                }
    
                .box4{
                    padding-top: 25px;
                    width: 160px;
                    height: 240px;
                    padding-right: 10px;
                    // padding: 10px 10px;
                    .logo{
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 10px;
                        width: 40px;
                        height: 40px;
                    }

                    .title{
                        font-size: 1rem;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                    .desc{
                        font-size: 0.6rem;
                        line-height: 12px;
                        padding-left: 15px;
                        padding-right: 15px
                    }
                }
            }
        }

        
    }
    
}

// @media  (max-width: 300px) {
   
//     .services .servicesContainer{
//         display: flex;
//         flex-direction: column;
//         padding-top: 20px;
//         padding-right: 15px !important;
//         padding-left: 15px !important;
//         align-items: center;

//         height: auto;
//         margin-bottom: 20px;
        
//         .servicesLeft{
//             align-items: center !important;
//             justify-content: center;
//             margin-top: 10px;
            
//             .descriptionDiv{
//                 margin-right: 0px;

//                 .desc{
//                     display: flex;
//                     width: 100%;
//                     text-align: justify;
//                     line-height: 23px;
//                 }
//             }
//         }

//         .servicesRight{
//             align-items: center !important;
//             justify-content: center !important;
//             gap: 5px;
//             display: flex;
//             // padding-right: 120px;
            
//             .containerOne{
//                 padding-right: 15px !important;
//                 padding-left: 15px !important;
//                 align-items: center;
//                 justify-content: center;
//                 width: 160px;
//                 height: 600px;

//                 .box1{
//                     width: 160px;
//                     padding: 10px 10px;
//                     height: 240px;

//                     .logo{
//                         padding-top: 2px;
//                         padding-bottom: 2px;
//                         padding-left: 10px;
//                         width: 40px;
//                         height: 40px;
//                     }

//                     .title{
//                         font-size: 1rem;
//                         padding-left: 20px;
//                         padding-right: 20px;
//                     }
//                     .desc{
//                         font-size: 0.6rem;
//                         line-height: 12px;
//                         padding-left: 15px;
//                         padding-right: 15px
//                     }
//                 }

//                 .box2{
//                     padding: 10px 10px;
//                     padding-top: 20px;
//                     width: 160px;
//                     height: 240px;
//                     .logo{
//                         padding-top: 2px;
//                         padding-bottom: 2px;
//                         padding-left: 10px;
//                         width: 40px;
//                         height: 40px;
//                     }

//                     .title{
//                         font-size: 1rem;
//                         padding-left: 20px;
//                         padding-right: 20px;
//                     }
//                     .desc{
//                         font-size: 0.6rem;
//                         line-height: 12px;
//                         padding-left: 15px;
//                         padding-right: 15px
//                     }
//                 }

                
//             }
            
//             .containerTwo{
//                 width: 160px;
//                 height: 600px;
//                 align-items: center;
//                 justify-content: center;
//                 padding-right: 15px !important;
//                 padding-left: 15px !important;
//                 gap: 10px;
//                 margin-top: 20px;
//                 .box3{
//                     width: 160px;
//                     padding-right: 10px;
//                     // padding: 10px 10px;
                    
//                     height: 240px;
//                     .logo{
//                         padding-top: 2px;
//                         padding-bottom: 2px;
//                         padding-left: 10px;
//                         width: 40px;
//                         height: 40px;
//                     }

//                     .title{
//                         font-size: 1rem;
//                         padding-left: 20px;
//                         padding-right: 20px;
//                     }
//                     .desc{
//                         font-size: 0.6rem;
//                         line-height: 12px;
//                         padding-left: 15px;
//                         padding-right: 15px
//                     }
//                 }
    
//                 .box4{
//                     padding-top: 25px;
//                     width: 160px;
//                     height: 240px;
//                     padding-right: 10px;
//                     // padding: 10px 10px;
//                     .logo{
//                         padding-top: 2px;
//                         padding-bottom: 2px;
//                         padding-left: 10px;
//                         width: 40px;
//                         height: 40px;
//                     }

//                     .title{
//                         font-size: 1rem;
//                         padding-left: 20px;
//                         padding-right: 20px;
//                     }
//                     .desc{
//                         font-size: 0.6rem;
//                         line-height: 12px;
//                         padding-left: 15px;
//                         padding-right: 15px
//                     }
//                 }
//             }
//         }

        
//     }
    
// }