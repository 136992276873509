.connectwithusBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    height: 170px;
    padding-top: 20px;
    // margin: 30px;

    .subtitle{
        color: #020E5A;
        font-size: 2.5rem;
        font-weight: bold;
        margin-right: 20px;
    }

    .roleBtn{
        width:233px ;
         height:54px;
         background-color: #D22599;
         font-size: 1.2rem;
         color: #FFFF;
         border: none;
        border-radius:70px;
        border:none;
        cursor: pointer;
    }
    
}

@media (min-width: 320px)  and (max-width: 1600px){
    .connectwithusBox{
        padding-top: 30px;
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center;

        .subtitle{
            font-size: 1.1rem;
            margin-right: 0px !important;
        }
        .roleBtn{
            margin-top: 20px !important;
            width: 173px;
            height: 34px;
            border-radius: 30px;
        }
    }
}