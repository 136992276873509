.footer{
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    // padding-left: 150px;
    // padding-right: 150px;
    background-color: #040E56;
    display: flex;
    flex-direction: row;
    // flex: 1;
    gap: 30px;

    .footerLeft{
        display: flex;
        align-items: flex-start;
        padding-top: 90px;
        padding-left: 150px;
        // margin: 90px 60px;
        // padding-left: 30px;
        // flex: 0.5;
       
        flex-direction: column;

        .companyLogo{
            width: 150px;
            height: 60px;
        }

        .breaker{
            width: 100%;
            padding-top: 30px;
        }

        .copyright{
            padding-top: 120px;
            color: white;


            p{
                color: 'white';
                font-size: 1.0rem;
            }
        }

    }

    .footerRight{
        display: flex;
        // flex: 0.5;
        flex-direction: column;
        // margin: 90px 60px;
        padding-right: 150px;

        .top{
            padding-top: 90px;
            // padding-left: 65%;
            color: #05A8DA;
            letter-spacing: 2px;
            font-size: 0.9rem;

            
        }
        .middle{
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;

            .name{
                width: 220px;
                height: 30px;
                border: none;
                border-radius: 30px;
                padding-left: 20px;
            }
            .email{
                width: 260px;
                height: 30px;
                border: none;
                border-radius: 30px;
                margin-left: 30px;
                padding-left: 20px;
            }
            .submitBtn{
                width: 160px;
                height: 30px;
                border: none;
                border-radius: 30px;
                margin-left: 30px;
                background-color: #D1299B;
                color: #FFFF;
            }


        }

        .social{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 20px;
            margin-top: 27px;
            padding-left: 70%;

            .mediaImg{
                width: 45px;
                height: 45px;
                padding: 20px;
            }

        }

        .bottom{
            display: flex;
            margin-top: 10px;
            width: 100%;
            height: 30px;
            flex-direction: row;
            padding: 20px;
            color: white;
            cursor: pointer;
            padding-left: 15%;
            
            p{
                color: 'white';
                font-size: 1.0rem;
                padding: 40px;
            }

        }
    }
}

@media (min-width: 600px ) and (max-width: 1600px) {
    .footer{
        width: 100%;
        display: flex;
        flex-direction: column-reverse !important;
        align-items: center;
        height: auto;
        gap: -20px;

        .footerLeft{
            height: auto;
            flex-direction: column;
            padding-left: 20px !important;
            padding-right: 20px !important;
            gap: 0px;
            align-items: center;

            .copyright{
                padding-top: 20px;
            }
            
            
        }

        .footerRight{
            display: flex;
            align-items: center;
            padding-left: 20px !important;
            padding-right: 20px !important;

            .middle{
                padding-left: 0px;
            }

            
        }
    }
    
}

@media (max-width: 600px) {
    .footer{
        width: 100%;
        display: flex;
        flex-direction: column-reverse !important;
        height: auto;
        gap: -20px;

        .footerLeft{
            height: auto;
            flex-direction: column;
            padding-left: 20px !important;
            padding-right: 20px !important;
            gap: 0px;
            align-items: center;

            .copyright{
                padding-top: 20px;
            }

            
            
            
        }

        .footerRight{
            display: flex;
            align-items: center;
            padding-left: 10px !important;
            padding-right: 10px !important;

            .top{
                padding-left: 20px !important;
            }

            .middle{
                // padding-left: 10px !important;
                padding-right: 10px !important;
                display: flex !important;
                flex-direction: column !important;
                align-items: center;
                height: auto;
                gap: 10px;
                .name {
                    padding-left: 0px;
                    width: 220px !important;
                    height: 30px !important;
                    margin-left: 30px;
                    padding-left: 20px;
                    
                }
                .email {
                    padding-left: 0px;
                    width: 220px !important;
                    height: 30px !important;
                    margin-left: 30px;
                    padding-left: 20px;
                    
                }

                .submitBtn {
                    padding-left: 0px;
                    width: 220px !important;
                    height: 30px !important;
                    margin-left: 30px;
                    padding-left: 20px;
                    
                }
            }
            .social{
                padding-left: 23%;
                .mediaImg{
                    width: 55px;
                    height: 55px;
                }
            }

            .bottom{
                display: flex;
                flex-direction: row !important;
                padding-left: 5px;
                padding-right: 5px;

                p{
                    padding-top: 40px !important;
                    padding: 15px;
                }
            }

            
        }
    }
    
}