.navbar{
    // overflow: hidden;
    background-color: transparent !important;
    width: 100%;
    height: 85px;
    top: -5px;
    display: flex;
    // flex-direction: row;
    justify-content: center;
    position: sticky;
    align-items: center;
    z-index: 13;
    
    // .navContainer{
    //     display: flex;
    //     flex: 1;
        .logoContainer{
            // flex : 0.3;
            // display: flex;
            justify-content: 'flex-start';
            padding-right: 20%;
            padding-top: 70px;
    
    
            .logo{

                width: 210px;
                height: 76px;
            }
        }
    
        .linksContainer{
            // flex : 0.7;
            display: flex;
            text-transform: uppercase;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            text-decoration: none;
            list-style: none;
            width: 40vw; 
            height: 85px;
            // justify-content: end;
            // margin-right: 2rem;
            z-index: 13;

            .nav-menu{
                display: flex;
                flex-direction: row;
                // margin-right: 1rem;
                z-index: 13;

                .nav-item{
                    width: 135px;
                    margin-top: 120px;
                    height: 85px;
                    font-size: 17px;
                    letter-spacing: 4px;
                    list-style: none;
                    text-decoration: none;
                    // padding-left: 30px;
                    padding-left: 30px;
                }
            }

            .links{
                text-decoration: none;
                list-style: none;
                color: white;
            
    
                // h4{
                //     margin: 40px;
                //     font-size: 13px;
                //     font-weight:normal;
                //     cursor: pointer;
                //     color :#020E5A;
                //     text-decoration: none;
                // }
            }
    
            .links:hover{
    
                    color: #BD3B98;
            }
    
    
            
    
    
        }

    // }
    
}

.navbar-active{
    
        // overflow: hidden;
        background-color: white !important;
        width: 100%;
        height: 85px;
        top: -5px;
        display: flex;
        // flex-direction: row;
        justify-content: center;
        position: sticky;
        align-items: center;
        z-index: 13;
        
        // .navContainer{
        //     display: flex;
        //     flex: 1;
            .logoContainer{
    
                // flex : 0.3;
                // display: flex;
                padding-top: 10px;
                justify-content: 'flex-start';
                padding-right: 10px;
                justify-content: center;
                padding-right: 20%;
                
        
        
                .logo{
                    width: 140px;
                    height: 50px;
                }
            }
        
            .linksContainer{
                // flex : 0.7;
                display: flex;
                text-transform: uppercase;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                text-decoration: none;
                list-style: none;
                width: 50vw; 
                height: 85px;
                // justify-content: end;
                // margin-right: 2rem;
                z-index: 13;
    
                .nav-menu{
                    display: flex;
                    flex-direction: row;
                    // margin-right: 2rem;
                    z-index: 13;
                    gap: 30px;
    
                    .nav-item{
                        width: 95px;
                        margin-top: 60px;
                        height: 85px;
                        font-size: 17px;
                        letter-spacing: 4px;
                        list-style: none;
                        text-decoration: none;
                        padding-left: 30px;
                    }
                }
    
                .links{
                    text-decoration: none;
                    list-style: none;
                    line-height: 40px;
                
        
                    // h4{
                    //     margin: 40px;
                    //     font-size: 13px;
                    //     font-weight:normal;
                    //     cursor: pointer;
                    //     color :#020E5A;
                    //     text-decoration: none;
                    // }
                }
        
                .links:hover{
        
                        color: #BD3B98;
                }
        
        
                
        
        
            }
    
        // }
        
    
}

// @media (min-width : 1810px)  {
//     .navbar .linksContainer .nav-menu .nav-item {
//         font-size:14px;
//         padding-left: auto;
//     }
// }


@media (min-width : 960px)  {
    .navbar-active .linksContainer .nav-menu .nav-item {
        font-size:15px;
        height: 95px;
        letter-spacing: 2px;
        padding-left: auto;
    }
}

@media (min-width : 720px) and (max-width : 1500px) {
    .navbar .linksContainer .nav-menu .nav-item {
        font-size:16px;

    }

    .navbar{
        .logoContainer{
            padding-right: 35%;
        }

       .nav-menu{
        margin-right: 230px;
       }
    }
}