.digitalScreen{
    width: 100%;
    height: 975px;
    display: flex;
    position: relative;
    
    // align-items: center;
    flex: 1;

    .alignmentLeft{
        margin-left: 201px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 0.3;

        .digitalLeft{
            padding-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 100px;
    
            .leftImg{
                width: 605px;
                height: 765px;
                z-index: 1;
                position: relative;
    
                object-position: 200 , 1195;
    
            }
    
        }
    }

    .alignmentRight{
        margin-right: 201px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start !important;
        flex: 0.5;
        position: relative;

        .digitalRight{
            width: 100%;
            padding-top: 90px;
            flex-direction: column;
            align-items: flex-start;
            // justify-content: space-evenly;
            position: absolute;
            // left: 150px;

            
    
            .title{
                color : #817F7F;
                padding-right: 590px;
            }
    
            .subtitleDiv{
                line-height: 4px;
                padding-right: 200px;
                
    
                .subtitle{
                    color: #020E5A;
                    font-size: 2.5rem;
                    font-weight: bold;
                }
                .divBelowsubtitle{
                    margin-left:110px;
                    margin-top: 60px;
                    width: 76px;
                    height: 2px;
                    background-color: #C13895;
                }
            }
    
    
            .descriptionDiv{
                width: 100%;
                display: flex;
                padding-top: 90px;
                padding-left: 55px;
                line-height: 42px;
                // align-items: start;
                
                p{
                    display: flex;

                    // flex-direction: column;
                    align-items: flex-start;
                    margin-left: 55px;
                    color : #000000;
                    font-size : 22px
                }
            }
    
            .descriptionImg{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 753px;
                height: 186px;
                margin-top: 50px;
                // object-position: 672px 1640px;
                background-color: #477DFF;
                position: absolute;
                z-index: 2;
                right: 180px;
                // left: 950px;
                line-height: 0.2;
    
                .descTitle{
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: #FFFFFF;
    
                }
    
                .descNo{
                    text-transform: uppercase;
                    font-size: 2.2rem;
                    color: #FFFFFF;
    
    
                }
                
                
                
                
            }
    
        }
    }

}