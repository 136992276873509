.location {
  top: 0px;
  width: 100%;
  height: 1450px;
  display: flex;
  margin-left: 150px;
  margin-right: 150px;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;

  .locationContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    // flex: 1;
    padding-top: 50px;
    gap: 50px;
    // padding-left: 500px;

    .top {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      // flex: 0.3;

      .subtitle {
        font-size: 2.5rem;
        font-weight: bold;
        color: #020e5a;
      }
      .divBelowsubtitle {
        margin-top: 10px;
        width: 76px;
        height: 2px;
        background-color: #c13895;
      }
    }

    .bottom {
      margin-top: 50px;
      display: flex;
      margin-left: 120px;
      margin-right: 120px;
      // flex: 0.7;
      // margin-left: 10%;
      // position: absolute;
      flex-direction: column;
      
      .locationOne{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .containerOne {
          display: flex;
          flex-direction: column;
          width: 320px;
          height: 450px;
          padding: 25px;
          margin: 30px;
          
          .box1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            height: 190px;
            position: relative;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            // background-color: #477DFF;
  
            .boxImg {
              width: 100%;
              height: 100%;
            }
          }
  
          .box2 {
            display: flex;
            flex-direction: column;
            text-align: start;
            width: 100%;
            height: 70%;
            background-color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            h4{
              padding-left: 20px;
              padding-right: 20px;
              text-transform: uppercase;
              letter-spacing: 2px;
            }
            p{
              font-size: 1.2rem;
              padding-left: 20px;
              padding-right: 20px;
            }
  
          }
        }
      }

      .locationTwo{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 20px;
        gap: 30px;
        // justify-content: center;

        .containerOne {
          display: flex;
          flex-direction: column;
          width: 320px;
          height: 450px;
          padding: 25px;
          margin: 30px;
          
          .box1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            height: 190px;
            position: relative;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            // background-color: #477DFF;
  
            .boxImg {
              width: 100%;
              height: 100%;
              display: flex;
              // background-image: linear-gradient(rgba(0, 0, 0, 0.7), #477dff);
            }
          }
  
          .box2 {
            display: flex;
            flex-direction: column;
            text-align: start;
            width: 100%;
            height: 70%;
            background-color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            
            h4{
              padding-left: 20px;
              padding-right: 20px;
              text-transform: uppercase;
              letter-spacing: 2px;
            }
            p{
              font-size: 1rem;
              padding-left: 20px;
              padding-right: 20px;
            }
          }
        }
      }

    }
  }
}

@media (min-width : 1200px) and (max-width: 1600px) {
  .location{
    height: 1450px !important;
    margin-left: 20px !important ;
    margin-right: 20px !important;
    .locationContainer{
      align-items: center !important;
      display: flex !important;
      padding-left: 500px !important;
      padding-right: 300px !important;


      .top{
        margin-top: 20px !important;
        display: flex !important;
        align-items: center !important;
        // justify-content: center;
        flex-direction: column !important;
        justify-content: center !important;
        

        .subtitle{
          display: flex;
          padding-left: 150px !important;
          padding-right: 50px !important;
          font-size: 2.5rem !important;
        }

        .divBelowsubtitle{
          margin-top: 5px !important;
        }

        .divBelowsubtitle { 
          margin-top: 5px !important;
          width: 56px;
          height: 2px;
          background-color: #c13895;
        }
        // margin-left: 150px;
        // margin-right: 150px;
      }

      .bottom {
        display: flex;
        margin-left: 10px !important;
        margin-right: 10px !important;
        padding-right: 10px !important;
        // flex: 0.7;
        // margin-left: 10%;
        position: absolute;
        flex-direction: column;
        gap: 60px !important;
        
        .locationOne{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 60px !important;
  
          .containerOne {
            display: flex;
            flex-direction: column;
            width: 190px !important;
            height: 130px !important;
            margin: 0px;
            padding: 5px ;
            gap: -20px !important;
            
            .box1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: 100%;
              height: 100% !important;
              // position: relative;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              // background-color: #477DFF;
    
              .boxImg {
         
                width: 100%;
                height: 100%;
              }
            }
    
            .box2 {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 100% !important;
              height: 120px !important;
              background-color: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
              h4{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
              p{
                display: none !important;
                font-size: 0.6rem !important;
                padding-left: 0px;
                padding-right: 0px;
              }
    
            }
          }
        }
        
  
        .locationTwo{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 60px !important;
          padding-top: 50px !important;
          padding-left: 0px !important;
          padding-right: 10px !important;
  
          .containerOne {
            display: flex;
            flex-direction: column;
            width: 190px !important;
            height: 130px !important;
            margin: 0px;
            padding: 0px ;
            
            .box1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: 190px;
              height: 100%;
              position: relative;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              // background-color: #477DFF;
    
              .boxImg {
         
                width: 100%;
                height: 100%;
              }
            }
    
            .box2 {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 100% !important;
              height: 120px !important;
              background-color: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
              h4{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
              p{
                display: none !important;
                font-size: 0.6rem !important;
                padding-left: 0px;
                padding-right: 0px;
              }
    
            }
          }
        }
      }

   

//         .bottom{
//  margin-left: 0px;
//         margin-right: 0px;

//         .servicesdescOne{
//           gap: 20px;
//         }
//         .servicesdescTwo{
//           gap: 20px;
//         }
//         .servicesdescThree{
//           gap: 20px;
//         }
//         }
    }
  }
}


@media (min-width : 760px) and (max-width: 1200px) {
  .location{
    height: 850px !important;
    margin-left: 20px !important ;
    margin-right: 20px !important;
    .locationContainer{
      gap: 10px;
      align-items: center !important;
      display: flex !important;
      padding-left: 120px !important;
      padding-right: 100px !important;


      .top{
        margin-top: 20px !important;
        display: flex !important;
        align-items: center !important;
        // justify-content: center;
        flex-direction: column !important;
        justify-content: center !important;
        

        .subtitle{
          display: flex;
          padding-left: 200px !important;
          padding-right: 50px !important;
          font-size: 2.5rem !important;
        }

        .divBelowsubtitle{
          margin-top: 5px !important;
        }

        .divBelowsubtitle { 
          margin-top: 5px !important;
          width: 56px;
          height: 2px;
          background-color: #c13895;
        }
        // margin-left: 150px;
        // margin-right: 150px;
      }

      .bottom {
        padding-top: 150px !important;
        display: flex;
        margin-left: 10px !important;
        margin-right: 10px !important;
        padding-right: 10px !important;
        // flex: 0.7;
        // margin-left: 10%;
        position: absolute;
        flex-direction: column;
        gap: 60px !important;
        
        .locationOne{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 60px !important;
  
          .containerOne {
            display: flex;
            flex-direction: column;
            width: 190px !important;
            height: 130px !important;
            margin: 0px;
            padding: 5px ;
            gap: -20px !important;
            
            .box1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: 100%;
              height: 100% !important;
              // position: relative;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              // background-color: #477DFF;
    
              .boxImg {
         
                width: 100%;
                height: 100%;
              }
            }
    
            .box2 {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 100% !important;
              height: 120px !important;
              background-color: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
              h4{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
              p{
                display: none !important;
                font-size: 0.6rem !important;
                padding-left: 0px;
                padding-right: 0px;
              }
    
            }
          }
        }
        
  
        .locationTwo{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 60px !important;
          padding-top: 50px !important;
          padding-left: 0px !important;
          padding-right: 10px !important;
  
          .containerOne {
            display: flex;
            flex-direction: column;
            width: 190px !important;
            height: 130px !important;
            margin: 0px;
            padding: 0px ;
            
            .box1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: 190px;
              height: 100%;
              position: relative;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              // background-color: #477DFF;
    
              .boxImg {
         
                width: 100%;
                height: 100%;
              }
            }
    
            .box2 {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 100% !important;
              height: 120px !important;
              background-color: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
              h4{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
              p{
                display: none !important;
                font-size: 0.6rem !important;
                padding-left: 0px;
                padding-right: 0px;
              }
    
            }
          }
        }
      }

   

//         .bottom{
//  margin-left: 0px;
//         margin-right: 0px;

//         .servicesdescOne{
//           gap: 20px;
//         }
//         .servicesdescTwo{
//           gap: 20px;
//         }
//         .servicesdescThree{
//           gap: 20px;
//         }
//         }
    }
  }
}

@media (min-width : 660px) and (max-width: 760px) {
  .location{
    height: 650px !important;
    margin-left: 20px !important ;
    margin-right: 20px !important;
    .locationContainer{
      padding-left: 200px !important;
      gap: 10px;
      display: flex;


      .top{
        margin-top: 120px !important;
        display: flex;
        align-items: center !important;
        // justify-content: center;
        flex-direction: column;
        justify-content: center !important;
        padding-left: 50px !important;

        .subtitle{
          padding-left: 30px !important ;
          padding-right: 30px !important;
          font-size: 1.5rem !important;
        }

        .divBelowsubtitle{
          margin-top: 5px !important;
        }

        .divBelowsubtitle { 
          margin-top: 5px !important;
          width: 56px;
          height: 2px;
          background-color: #c13895;
        }
        // margin-left: 150px;
        // margin-right: 150px;
      }

      .bottom {
        padding-top: 150px !important;
        display: flex;
        margin-left: 0px !important;
        margin-right: 10px !important;
        padding-right: 20px !important;
        // flex: 0.7;
        // margin-left: 10%;
        position: absolute;
        flex-direction: column;
        gap: 20px !important;
        
        .locationOne{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 20px !important;
  
          .containerOne {
            display: flex;
            flex-direction: column;
            width: 90px !important;
            height: 130px !important;
            margin: 0px;
            padding: 5px ;
            gap: -20px !important;
            
            .box1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: 100%;
              height: 100% !important;
              // position: relative;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              // background-color: #477DFF;
    
              .boxImg {
         
                width: 100%;
                height: 100%;
              }
            }
    
            .box2 {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 100% !important;
              height: 120px !important;
              background-color: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
              h4{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
              p{
                display: none !important;
                font-size: 0.6rem !important;
                padding-left: 0px;
                padding-right: 0px;
              }
    
            }
          }
        }
        
  
        .locationTwo{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 10px !important;
          padding-left: 0px !important;
          padding-right: 20px !important;
  
          .containerOne {
            display: flex;
            flex-direction: column;
            width: 130px !important;
            height: 120px !important;
            margin: 0px;
            padding: 0px ;
            
            .box1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: 100%;
              height: 100%;
              position: relative;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              // background-color: #477DFF;
    
              .boxImg {
         
                width: 100%;
                height: 100%;
              }
            }
    
            .box2 {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 100% !important;
              height: 120px !important;
              background-color: white;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
              h4{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
              p{
                display: none !important;
                font-size: 0.6rem !important;
                padding-left: 0px;
                padding-right: 0px;
              }
    
            }
          }
        }
      }

   

//         .bottom{
//  margin-left: 0px;
//         margin-right: 0px;

//         .servicesdescOne{
//           gap: 20px;
//         }
//         .servicesdescTwo{
//           gap: 20px;
//         }
//         .servicesdescThree{
//           gap: 20px;
//         }
//         }
    }
  }
}


  @media (min-width : 320px) and (max-width: 660px) {
    .location{
      height: 650px !important;
      margin-left: 20px !important ;
      margin-right: 20px !important;
      .locationContainer{
        gap: 10px;
        display: flex;


        .top{
          margin-top: 120px !important;
          display: flex;
          align-items: center !important;
          // justify-content: center;
          flex-direction: column;
          justify-content: center !important;
          padding-left: 50px !important;

          .subtitle{
            padding-left: 30px !important ;
            padding-right: 30px !important;
            font-size: 1.5rem !important;
          }

          .divBelowsubtitle{
            margin-top: 5px !important;
          }

          .divBelowsubtitle { 
            margin-top: 5px !important;
            width: 56px;
            height: 2px;
            background-color: #c13895;
          }
          // margin-left: 150px;
          // margin-right: 150px;
        }

        .bottom {
          padding-top: 150px !important;
          display: flex;
          margin-left: 0px !important;
          margin-right: 10px !important;
          padding-right: 20px !important;
          // flex: 0.7;
          // margin-left: 10%;
          position: absolute;
          flex-direction: column;
          gap: 20px !important;
          
          .locationOne{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 20px !important;
    
            .containerOne {
              display: flex;
              flex-direction: column;
              width: 90px !important;
              height: 130px !important;
              margin: 0px;
              padding: 5px ;
              gap: -20px !important;
              
              .box1 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                height: 100% !important;
                // position: relative;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                // background-color: #477DFF;
      
                .boxImg {
           
                  width: 100%;
                  height: 100%;
                }
              }
      
              .box2 {
                display: flex;
                flex-direction: column;
                text-align: center;
                width: 100% !important;
                height: 120px !important;
                background-color: white;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
                h4{
                  padding-left: 0px;
                  padding-right: 0px;
                  font-size: 0.7rem;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                }
                p{
                  display: none !important;
                  font-size: 0.6rem !important;
                  padding-left: 0px;
                  padding-right: 0px;
                }
      
              }
            }
          }
          
    
          .locationTwo{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px !important;
            padding-left: 0px !important;
            padding-right: 20px !important;
    
            .containerOne {
              display: flex;
              flex-direction: column;
              width: 130px !important;
              height: 120px !important;
              margin: 0px;
              padding: 0px ;
              
              .box1 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: relative;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                // background-color: #477DFF;
      
                .boxImg {
           
                  width: 100%;
                  height: 100%;
                }
              }
      
              .box2 {
                display: flex;
                flex-direction: column;
                text-align: center;
                width: 100% !important;
                height: 120px !important;
                background-color: white;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
                h4{
                  padding-left: 0px;
                  padding-right: 0px;
                  font-size: 0.7rem;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                }
                p{
                  display: none !important;
                  font-size: 0.6rem !important;
                  padding-left: 0px;
                  padding-right: 0px;
                }
      
              }
            }
          }
        }

     

//         .bottom{
//  margin-left: 0px;
//         margin-right: 0px;

//         .servicesdescOne{
//           gap: 20px;
//         }
//         .servicesdescTwo{
//           gap: 20px;
//         }
//         .servicesdescThree{
//           gap: 20px;
//         }
//         }
      }
    }
  }

