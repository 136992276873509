.blurBg{
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 100%;
    background-color: #fff;
    background-color: rgba(255,255,255,0.5);


    h4{
        position: relative;
        margin-top: 20%;
        margin-left: 50%;
    }
    // top: 50%;
    // bottom: 0;
    // left: 50%;
    // right: 0;
    // transform: translate(-50% ,-50%);

}