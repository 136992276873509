.styles{
    margin-left: 150px;
    margin-right: 150px;
    .title{
        font-weight: bold;
        font-size: 65px;
        padding-right:'835px';
        color 
        :#FFFFFF;
    }
    .bannerImgs{
        height: 960px;
    }
}


@media (min-width : 960px){
    .styles{
        margin-left: auto;
        margin-right: auto;
    }

    title{
        font-size: auto;
    }

    .bannerImgs{
        height: auto;
    }
}

@media (min-width : 720px){
    .styles{
        margin-left: auto;
        margin-right: auto;
    }

    title{
        font-size: 25px;
    }

    .bannerImgs{
        height: auto;
    }
}

@media (min-width : 320px) and (min-width : 780px){
    .styles{
        margin-left: auto;
        margin-right: auto;
    }

    title{
        font-size: auto;
    }

    .bannerImgs{
        position: absolute;
        width: auto !important;
        height: 450px !important;
    }
}